import { api } from '../services/Axios';
import React, { useRef, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import '../css/AddBrokerSource.css';
import Header from './Header';
import { Alert } from 'react-bootstrap';
import { Spin } from 'antd';
import { antIcon } from '../services/Constants';

const brokerInitial = {
    bfirstName: "",
    blastName: "",
    bemail: "",
    bphone: "",
    baddress: "",
    breraID: "",
    bcompanyName: "",
    bcompanyEmail: "",
    bcompanyPhone: "",
    bcompanyAddress: "",
    executiveFirstName: "",
    executiveLastName: "",
    executivePhone: "",
    executiveEmail: "",
}

const initialBrokerError = {
    code: "",
    bfirstName: "",
    blastName: "",
    bemail: "",
    bphone: "",
    breraID: "",
    bcompanyEmail: "",
    bcompanyPhone: "",
    executiveEmail: "",
    executivePhone: "",
}

const AddBrokerSource = () => {

    const [loading, setLoading] = useState(false);
    const [brokerCredentials, setBrokerCredentials] = useState(brokerInitial);
    const [variant, setVariant] = useState("");
    const [brokerError, setBrokerError] = useState(initialBrokerError);

    const brokerFormRef = useRef();

    const onBrokerInputChange = (e) => {
        setBrokerCredentials((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const addBrokerSource = (e) => {
        e.preventDefault();
        if (validateBrokerForm()) {
            setLoading(true);
            api.post(`${process.env.REACT_APP_API_URL}/add_broker_source`, brokerCredentials)
                .then((res) => {
                    if (res.data.message && res.data.message !== "success") {
                        setVariant("danger");
                        setBrokerError((prev) => ({ ...prev, code: res.data.message }));
                        setTimeout(() => {
                            setBrokerError((prev) => ({ ...prev, code: "" }));
                            setVariant("");
                        }, 5000)
                    } else {
                        // setExternalBrokers(res.data);
                        setBrokerCredentials(brokerInitial);
                        // setShowModal(false);
                        brokerFormRef.current.reset();
                        setVariant("success");
                        setBrokerError((prev) => ({ ...prev, code: "Source Broker added successfully!" }));
                        setTimeout(() => {
                            setBrokerError((prev) => ({ ...prev, code: "" }));
                            setVariant("");
                        }, 5000)
                    }
                })
                .catch((err) => {
                    // console.log(err);
                    setBrokerError((prev) => ({ ...prev, code: "Something went wrong, please try again later!" }));
                    setTimeout(() => {
                        setBrokerError((prev) => ({ ...prev, code: "" }));
                    }, 5000)
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }

    const validateBrokerForm = () => {
        let flag = false;
        if (brokerCredentials.bfirstName === "") {
            setBrokerError((prev) => ({ ...prev, bfirstName: "First Name is required" }));
            flag = true;
        }
        if (brokerCredentials.blastName === "") {
            setBrokerError((prev) => ({ ...prev, blastName: "Last Name is required" }));
            flag = true;
        }
        if (/\s/.test(brokerCredentials.blastName)) {
            setBrokerError((prev) => ({ ...prev, blastName: "Last Name cannot contain spaces" }));
            flag = true;
        }
        if (brokerCredentials.bemail && !(/^\w+([\.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(brokerCredentials.bemail))) {
            setBrokerError((prev) => ({ ...prev, bemail: "Please enter a valid email address" }));
            flag = true;
        }
        if (brokerCredentials.bphone.match(/^[0-9]{10}$/) === null) {
            setBrokerError((prev) => ({ ...prev, bphone: "Please enter a valid 10 digit phone number" }));
            flag = true;
        }
        if (brokerCredentials.bphone === "") {
            setBrokerError((prev) => ({ ...prev, bphone: "Phone Number is required" }));
            flag = true;
        }
        if (brokerCredentials.breraID && (brokerCredentials.breraID.match(/^[0-9a-zA-Z]+$/) === null)) {
            setBrokerError((prev) => ({ ...prev, breraID: "Please enter a valid (alphanumeric) RERA ID" }));
            flag = true;
        }
        if (brokerCredentials.bcompanyEmail && !(/^\w+([\.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(brokerCredentials.bcompanyEmail))) {
            setBrokerError((prev) => ({ ...prev, bcompanyEmail: "Please enter a valid email address" }));
            flag = true;
        }
        if (brokerCredentials.bcompanyPhone && (brokerCredentials.bcompanyPhone.match(/^[0][1-9]\d{9}$/) === null && brokerCredentials.bcompanyPhone.match(/^[1-9]\d{9}$/) === null)) {
            setBrokerError((prev) => ({ ...prev, bcompanyPhone: "Please enter a valid phone number" }));
            flag = true;
        }
        if (brokerCredentials.executiveEmail && !(/^\w+([\.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(brokerCredentials.executiveEmail))) {
            setBrokerError((prev) => ({ ...prev, executiveEmail: "Please enter a valid email address" }));
            flag = true;
        }
        if (brokerCredentials.executivePhone && brokerCredentials.executivePhone.match(/^[0-9]{10}$/) === null) {
            setBrokerError((prev) => ({ ...prev, executivePhone: "Please enter a valid 10 digits phone number" }));
            flag = true;
        }
        if (flag) {
            return false;
        } else {
            return true;
        }
    }

    useEffect(() => {
        if (brokerCredentials.bfirstName !== "") {
            setBrokerError((prev) => ({ ...prev, bfirstName: "" }));
        }
        if (brokerCredentials.blastName !== "") {
            setBrokerError((prev) => ({ ...prev, blastName: "" }));
        }
        if (!(/\s/.test(brokerCredentials.blastName))) {
            setBrokerError((prev) => ({ ...prev, blastName: "" }));
        }
        if (!brokerCredentials.bemail || (brokerCredentials.bemail && (/^\w+([\.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(brokerCredentials.bemail)))) {
            setBrokerError((prev) => ({ ...prev, bemail: "" }));
        }
        if (brokerCredentials.bphone.match(/^[0-9]{10}$/) !== null) {
            setBrokerError((prev) => ({ ...prev, bphone: "" }));
        }
        if (brokerCredentials.bphone !== "") {
            setBrokerError((prev) => ({ ...prev, bphone: "" }));
        }
        if (!brokerCredentials.breraID || (brokerCredentials.breraID && brokerCredentials.breraID.match(/^[0-9a-zA-Z]+$/) !== null)) {
            setBrokerError((prev) => ({ ...prev, breraID: "" }));
        }
        if (!brokerCredentials.bcompanyEmail || (brokerCredentials.bcompanyEmail && (/^\w+([\.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(brokerCredentials.bcompanyEmail)))) {
            setBrokerError((prev) => ({ ...prev, bcompanyEmail: "" }));
        }
        if (!brokerCredentials.bcompanyPhone || (brokerCredentials.bcompanyPhone && (brokerCredentials.bcompanyPhone.match(/^[1-9]\d{9}$/) !== null || brokerCredentials.bcompanyPhone.match(/^[0][1-9]\d{9}$/) !== null))) {
            setBrokerError((prev) => ({ ...prev, bcompanyPhone: "" }));
        }
        if (!brokerCredentials.executiveEmail || (brokerCredentials.executiveEmail && (/^\w+([\.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(brokerCredentials.executiveEmail)))) {
            setBrokerError((prev) => ({ ...prev, executiveEmail: "" }));
        }
        if (!brokerCredentials.executivePhone || (brokerCredentials.executivePhone && brokerCredentials.executivePhone.match(/^[0-9]{10}$/) !== null)) {
            setBrokerError((prev) => ({ ...prev, executivePhone: "" }));
        }
    }, [brokerCredentials.bfirstName, brokerCredentials.blastName, brokerCredentials.bemail, brokerCredentials.bphone, brokerCredentials.breraID, brokerCredentials.bcompanyEmail, brokerCredentials.bcompanyPhone, brokerCredentials.executiveEmail, brokerCredentials.executivePhone]);

    return (
        <>
            <Helmet>
                <title>Brahmastra - Add Broker Source</title>
            </Helmet>
            <Header title="Add Broker Source" />
            <Spin indicator={antIcon} spinning={loading} size="large">
                <form id="add-broker-source-form" ref={brokerFormRef} onSubmit={(e) => addBrokerSource(e)}>
                    {brokerError.code && <><Alert variant={variant}>{brokerError.code}</Alert></>}
                    <div>
                        <span>
                            <label htmlFor="bfirstName">First Name<span style={{ color: "red" }}> *</span></label><br />
                            <input type="text" id="bfirstName" name="bfirstName" onChange={(e) => onBrokerInputChange(e)} /><br />
                            {brokerError.bfirstName && <><Alert variant="danger">{brokerError.bfirstName}</Alert></>}
                        </span>
                        <span>
                            <label htmlFor="blastName">Last Name<span style={{ color: "red" }}> *</span></label><br />
                            <input type="text" id="blastName" name="blastName" onChange={(e) => onBrokerInputChange(e)} /><br />
                            {brokerError.blastName && <><Alert variant="danger">{brokerError.blastName}</Alert></>}
                        </span>
                    </div>
                    <div>
                        <span>
                            <label htmlFor="bemail">Email</label><br />
                            <input type="text" id="bemail" name="bemail" onChange={(e) => onBrokerInputChange(e)} /><br />
                            {brokerError.bemail && <><Alert variant="danger">{brokerError.bemail}</Alert></>}
                        </span>
                        <span>
                            <label htmlFor="bphone">Phone<span style={{ color: "red" }}> *</span></label><br />
                            <input type="text" id="bphone" name="bphone" onChange={(e) => onBrokerInputChange(e)} /><br />
                            {brokerError.bphone && <><Alert variant="danger">{brokerError.bphone}</Alert></>}
                        </span>
                    </div>
                    <div>
                        <span>
                            <label htmlFor="baddress">Address</label><br />
                            <input type="text" id="baddress" name="baddress" onChange={(e) => onBrokerInputChange(e)} /><br />
                        </span>
                        <span>
                            <label htmlFor="breraID">RERA ID</label><br />
                            <input type="text" id="breraID" name="breraID" onChange={(e) => onBrokerInputChange(e)} /><br />
                            {brokerError.breraID && <><Alert variant="danger">{brokerError.breraID}</Alert></>}
                        </span>
                    </div>
                    <div>
                        <span>
                            <label htmlFor="bcompanyName">Firm Name</label><br />
                            <input type="text" id="bcompanyName" name="bcompanyName" onChange={(e) => onBrokerInputChange(e)} /><br />
                        </span>
                        <span>
                            <label htmlFor="bcompanyAddress">Firm Address</label><br />
                            <input type="text" id="bcompanyAddress" name="bcompanyAddress" onChange={(e) => onBrokerInputChange(e)} /><br />
                        </span>
                    </div>
                    <div>
                        <span>
                            <label htmlFor='bcompanyEmail'>Firm Email</label><br />
                            <input type="text" id="bcompanyEmail" name="bcompanyEmail" onChange={(e) => onBrokerInputChange(e)} /><br />
                            {brokerError.bcompanyEmail && <><Alert variant="danger">{brokerError.bcompanyEmail}</Alert></>}
                        </span>
                        <span>
                            <label htmlFor="bcompanyPhone">Firm Phone</label><br />
                            <input type="text" id="bcompanyPhone" name="bcompanyPhone" onChange={(e) => onBrokerInputChange(e)} /><br />
                            {brokerError.bcompanyPhone && <><Alert variant="danger">{brokerError.bcompanyPhone}</Alert></>}
                        </span>
                    </div>
                    <div>
                        <span>
                            <label htmlFor="executiveFirstName">Executive First Name</label><br />
                            <input type="text" id="executiveFirstName" name="executiveFirstName" onChange={(e) => onBrokerInputChange(e)} /><br />
                        </span>
                        <span>
                            <label htmlFor="executiveLastName">Executive Last Name</label><br />
                            <input type="text" id="executiveLastName" name="executiveLastName" onChange={(e) => onBrokerInputChange(e)} /><br />
                        </span>
                    </div>
                    <div>
                        <span>
                            <label htmlFor="executiveEmail">Executive Email</label><br />
                            <input type="text" id="executiveEmail" name="executiveEmail" onChange={(e) => onBrokerInputChange(e)} /><br />
                            {brokerError.executiveEmail && <><Alert variant="danger">{brokerError.executiveEmail}</Alert></>}
                        </span>
                        <span>
                            <label htmlFor="executivePhone">Executive Phone</label><br />
                            <input type="text" id="executivePhone" name="executivePhone" onChange={(e) => onBrokerInputChange(e)} /><br />
                            {brokerError.executivePhone && <><Alert variant="danger">{brokerError.executivePhone}</Alert></>}
                        </span>
                    </div>
                    <div className='manual-modal-footer'>
                        <button type="submit">Add</button>
                        <button type="reset" onClick={() => { setBrokerCredentials(brokerInitial); setBrokerError(initialBrokerError); brokerFormRef.current.reset(); }}>Reset</button>
                    </div>
                </form>
            </Spin>
        </>
    )
}

export default AddBrokerSource