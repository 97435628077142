import React, { useState, useEffect, useContext } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { contextData } from '../context/DataContext';

const FilterDropdown = ({ filters, filtersObject, addToFilter, removeFromFilter, resetFilterField, copyAllFilters, field, title, reset }) => {

    const { group } = useContext(contextData);
    const [selectAll, setSelectAll] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [searchFilterFields, setSearchFilterFields] = useState(field === "assignedTo" ? filters.assignedTo.map((element) => element.name) : filters[field]);

    const handleSelectAll = () => {
        if (selectAll) {
            resetFilterField(field);
            setSelectAll(false);
        } else {
            setSelectAll(true);
            copyAllFilters(field);
        }
    }

    const handleSelect = (element) => {
        if (filtersObject[field].includes(element)) {
            removeFromFilter(field, element);
        } else {
            addToFilter(field, element);
        }
    }

    const handleReset = () => {
        resetFilterField(field);
        if (["names", "mobiles", "brokerSources", "propertyNames", "propertyAreas", "assignedTo"].includes(field)) {
            setSearchString("");
            setSearchFilterFields(field === "assignedTo" ? filters.assignedTo.map((element) => element.name) : filters[field]);
        }
        if (selectAll) {
            setSelectAll(false);
        }
    }

    useEffect(() => {
        handleReset();
    }, [reset])

    useEffect(() => {
        if (searchString.length > 0) {
            let tempArr = [];
            if (field === "assignedTo") {
                filters.assignedTo.filter((value) => {
                    if (value.name.toString().toLowerCase().includes(searchString.toString().toLowerCase())) {
                        tempArr.push(value.name);
                    }
                    return null;
                });
            } else {
                filters[field].filter((value) => {
                    if (value.toString().toLowerCase().includes(searchString.toString().toLowerCase())) {
                        tempArr.push(value);
                    }
                    return null;
                });
            }
            setSearchFilterFields(tempArr);
        } else {
            setSearchFilterFields(field === "assignedTo" ? filters.assignedTo.map((element) => element.name) : filters[field]);
        }
    }, [searchString])

    useEffect(() => {
        setSearchFilterFields(field === "assignedTo" ? filters.assignedTo.map((element) => element.name) : filters[field])
    }, [filters[field].length])

    return (
        <div style={{ position: "relative" }}>
            <div style={filtersObject[field].length > 0 ? { position: "absolute", top: "-15%", right: "-5%", background: "#5D70D2", width: "2vh", height: "2vh", borderRadius: "50%", zIndex: 4 } : null}></div>
            <DropdownButton as={ButtonGroup} id={`${field}-dropdown`} title={title} style={{ minWidth: group === "employee" ? "6vw" : group === "individual" ? "8vw" : "none" }} >
                <div className='dropdown-outer-item-first'>
                    {(["names", "mobiles", "brokerSources", "propertyNames", "propertyAreas", "assignedTo"].includes(field)) && <input
                        type="text"
                        id={`${field}-searchbar`}
                        placeholder='Search'
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                    />}
                    {searchFilterFields.length > 0 &&
                        <div className='select-all-button'>
                            <input
                                type="checkbox"
                                id={`select-all-${field}`}
                                checked={filtersObject[field].length === filters[field].length}
                                onChange={() => handleSelectAll()}
                            />
                            <label htmlFor={`select-all-${field}`}>Select All</label>
                        </div>}
                </div>
                <Dropdown.Divider />
                <div className='checkbox-container'>
                    {filters[field].map((element, index) => {
                        return (
                            <div key={index} className='dropdown-menu-item' style={{ display: !searchFilterFields.includes(field === "assignedTo" ? element.name : element) ? "none" : "flex" }}>
                                <input
                                    type="checkbox"
                                    id={`${field}-${index}-checkbox`}
                                    checked={filtersObject[field].includes(field === "assignedTo" ? element.SBID : element)}
                                    onChange={() => handleSelect(field === "assignedTo" ? element.SBID : element)}
                                />
                                <label htmlFor={`${field}-${index}-checkbox`}>{field === "assignedTo" ? element.name : element}</label>
                            </div>
                        )
                    })}
                    <span style={{ display: searchFilterFields.length === 0 ? "inline-block" : "none" }}>Not Found</span>
                </div>
                <Dropdown.Divider />
                <div className='dropdown-outer-item'>
                    <button onClick={() => handleReset()} disabled={searchString === "" && filtersObject[field].length === 0 ? true : false} >Clear All</button>
                    <Dropdown.Item>
                        <button onClick={() => { return null }}>OK</button>
                    </Dropdown.Item>
                </div>
            </DropdownButton>
        </div>
    )
}

export default FilterDropdown