import React, { useContext } from 'react';
import moment from 'moment';
// import data from '../data.json';
import { useLocation } from 'react-router';
import { contextData } from '../context/DataContext';
import { reminderStatusArray } from '../services/Constants';

const LeadActivities = () => {

    const { leadsTableData } = useContext(contextData)
    const location = useLocation();

    return (
        <div id="activity-timeline">
            <div className="activity-wrapper">
                {leadsTableData.filter((element) => location.state.LID === element.LID)[0].history.map((data, index) => {
                    return (
                        <div className="activity" key={index}>
                            <span>{moment(data.activity).format('DD/MM/YYYY')}</span>
                            <span>Status : {data.status}</span>
                            <span>Remarked By - {data.name}</span>
                            <div>
                                {data.reminder !== "" && data.reminder !== null && reminderStatusArray.includes(data.status) &&
                                    <div>Next Reminder : {moment(data.reminder).format('DD/MM/YYYY')}</div>}
                                <div>Remarks : {data.remarks}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default LeadActivities