import React, { useEffect, useState, useContext } from 'react';
import { Table, Spin, Checkbox } from 'antd';
import { antIcon } from '../services/Constants';
import { contextData } from '../context/DataContext';
import { useNavigate } from "react-router-dom";
import { api } from '../services/Axios';
import Filters from './Filters';
import '../css/TableComp.css';
import { sourceArray, propertyTypesArray, dealTypesArray, statusArray } from '../services/Constants';

const filters = {
  names: [],
  mobiles: [],
  source: sourceArray,
  brokerSources: [],
  propertyNames: [],
  propertyAreas: [],
  propertyTypes: propertyTypesArray,
  dealTypes: dealTypesArray,
  statuses: statusArray,
  assignedTo: []
}

const TableComp = ({ csvLoading }) => {

  const { userColumns, adminColumns, group, leadsTableData, leadsTableDataCallback, isStatusModalOpen, tableLoading } = useContext(contextData);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ["20", "50", "100", "200", "500"],
      // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    },
  });

  const navigate = useNavigate();

  const handleSelect = (record, selected) => {
    if (selected) {
      setSelectedRowKeys((keys) => [...keys, record.LID]);
    } else {
      setSelectedRowKeys((keys) => {
        const index = keys.indexOf(record.LID);
        return [...keys.slice(0, index), ...keys.slice(index + 1)];
      });
    }
  };

  const toggleSelectAll = () => {
    setSelectedRowKeys((keys) =>
      keys.length === leadsTableData.length ? [] : leadsTableData.map((r) => r.LID)
    );
  };

  const rowSelection = {
    selectedRowKeys,
    // type: "checkbox",
    // fixed: false,
    onSelect: handleSelect,
    columnTitle: <Checkbox
      indeterminate={
        selectedRowKeys.length > 0 && selectedRowKeys.length < leadsTableData.length
      }
      checked={selectedRowKeys.length === leadsTableData.length && leadsTableData.length !== 0}
      onChange={toggleSelectAll}
    />
  };

  const uncheckSelection = () => {
    setSelectedRowKeys([]);
  }

  const handleTableChange = (pagination, sorter) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  const handleErrorCallback = (value) => {
    setError(value);
  }

  const handleLoadingCallback = (value) => {
    setLoading(value);
  }

  const filterFields = (value) => {
    value.filter((element) => {
      if (!filters.names.includes(element.full_name) && element.full_name !== "") {
        filters.names.push(element.full_name);
      }
      if (!filters.mobiles.includes(element.phone) && element.phone !== "") {
        filters.mobiles.push(element.phone);
      }
      if (!filters.propertyNames.includes(element.interested_property) && element.interested_property !== "") {
        filters.propertyNames.push(element.interested_property);
      }
      if (!filters.propertyAreas.includes(element.property_area) && element.property_area !== "") {
        filters.propertyAreas.push(element.property_area);
      }
      if (!filters.assignedTo.some((elem) => elem.SBID === element.SBID)) {
        filters.assignedTo.push({ SBID: element.SBID, name: element.SB_name });
      }
      for (let x in element.source) {
        if (!filters.source.includes(element.source[x]) && !filters.brokerSources.includes(element.source[x]) && element.source[x] !== "") {
          filters.brokerSources.push(element.source[x]);
        }
      }
      return null;
    });
  }

  const getLeads = async () => {
    setLoading(true);
    try {
      const response = await api.get(`${process.env.REACT_APP_API_URL}/get?table=leads`);
      leadsTableDataCallback(response.data);
      filterFields(response.data);
    } catch (err) {
      setError(err.message);
      leadsTableDataCallback([]);
    } finally {
      setLoading(false);
    };
  }

  useEffect(() => {
    getLeads();
  }, [])

  useEffect(() => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
        total: leadsTableData.length,
      },
    });
  }, [leadsTableData.length])

  useEffect(() => {
    filterFields(leadsTableData);
  }, [leadsTableData])

  return (
    !error ?
      <Spin indicator={antIcon} spinning={loading ? loading : csvLoading} size="large" >
        <Filters loadingCallback={handleLoadingCallback} errorCallback={handleErrorCallback} getLeads={getLeads} selectedLeads={selectedRowKeys} uncheckSelection={uncheckSelection} filters={filters} />
        <Table
          bordered
          size="small"
          columns={group === "admin" ? adminColumns : userColumns}
          rowKey={(leadsTableData) => leadsTableData.LID}
          rowSelection={group === "admin" && rowSelection}
          rowClassName={(record) => {
            return 'selectable-row';
          }}
          dataSource={leadsTableData}
          pagination={tableParams.pagination}
          loading={{ indicator: <Spin indicator={antIcon} />, spinning: tableLoading }}
          onChange={handleTableChange}
          scroll={{
            x: "150%",
            y: 450
          }}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                if (!isStatusModalOpen) {
                  navigate(`/leads/${record.full_name}`, { state: record });
                }
              }, // double click row
            };
          }}
        />
      </Spin>
      :
      <div className='error-text'>{`There is a problem fetching the table data - ${error}`}</div>
  )
}
export default TableComp