import React, { useEffect, useContext } from 'react';
import "../css/Navbar.css";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import { logout } from '../services/Auth';
import WithAuth from './WithAuth';
// import profileIcon from '../img/my-pic.png';
import logo from '../logo.png'
import { MdDashboard, MdFacebook, MdChecklist, MdAdminPanelSettings } from "react-icons/md";
import { FaUserFriends } from "react-icons/fa";
import { HiPlusCircle } from "react-icons/hi";
import { ConfigProvider } from 'antd';
import { contextData } from '../context/DataContext';
import { themeContext } from '../context/Theme';

const Navbar = () => {

  const { profile, group } = useContext(contextData);
  const { theme } = useContext(themeContext);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/dashboard');
    }
  }, [])

  return (
    <>
      <nav id="sidebar" data-theme={theme}>
        <div className='logo-block'>
          <NavLink className="nav-item" to='/dashboard' data-toggle="tab">
            <span className="icon">
              <img src={logo} alt="brahmastra - manifesting realty" className="brahmastra-logo" />
            </span>
            <h1 className='title'>Brahmastra</h1>
          </NavLink>
        </div>
        <div className='scrollable-links-block'>
          <NavLink className="nav-item" to="/dashboard" data-toggle="tab">
            <MdDashboard style={{ width: "1.6vw", height: "1.6vw" }} />
            <span className='title'>Dashboard</span>
          </NavLink>
          <NavLink className="nav-item" to="/leads" data-toggle="tab">
            <FaUserFriends style={{ width: "1.6vw", height: "1.6vw" }} />
            <span className='title'>Leads</span>
          </NavLink>
          <NavLink className="nav-item" to="/tasks" data-toggle="tab">
            <MdChecklist style={{ width: "1.6vw", height: "1.6vw" }} />
            <span className='title'>Tasks</span>
          </NavLink>
          <NavLink className="nav-item" to="/add-lead" data-toggle="tab">
            <HiPlusCircle style={{ width: "1.6vw", height: "1.6vw" }} />
            <span className='title'>Add Lead</span>
          </NavLink>
          {group !== "individual" && <NavLink className="nav-item" to="/add-broker-source" data-toggle="tab">
            <HiPlusCircle style={{ width: "1.6vw", height: "1.6vw" }} />
            <span className='title'>Add Broker Source</span>
          </NavLink>}
          {group === "admin" &&
            <>
              <NavLink className="nav-item" to="/add-employee" data-toggle="tab">
                <HiPlusCircle style={{ width: "1.6vw", height: "1.6vw" }} />
                <span className='title'>Add Employee</span>
              </NavLink>
              <NavLink className="nav-item" to="/manage-employees" data-toggle="tab">
                <MdAdminPanelSettings style={{ width: "1.6vw", height: "1.6vw" }} />
                <span className='title'>Manage Employees</span>
              </NavLink>
            </>}
          {/* <NavLink className="nav-item" to="/facebook-leads" data-toggle="tab">
          <MdFacebook style={{ width: "1.6vw", height: "1.6vw" }} />
          <span className='title'>Facebook Mappings</span>
        </NavLink> */}
        </div>
        <div className='last-block'>
          <NavLink className="last-nav-item" to="/profile" data-toggle="tab" end>
            {/* <div className='icon'><img src={profileIcon} alt="" className="profile-image" /></div> */}
            <div className='icon'>
              <span className='profile-image'>{profile.firstName && profile.firstName[0].toUpperCase()}</span>
            </div>
          </NavLink>
          <button className="last-nav-item" data-toggle="tab" onClick={() => logout()}>
            <span className='title'>Logout</span>
          </button>
        </div>
      </nav>
      <main data-theme={theme}>
        <ConfigProvider
          theme={{
            components: {
              Spin: {
                colorBgContainer: "rgba(0, 0, 0, 0)",
              },
            },
          }}
        >
          <div id="date-picker-container"></div>
          <Outlet />
        </ConfigProvider>
      </main>
    </>
  )
}
export default WithAuth(Navbar)