import React, { useState, useRef, useContext } from 'react';
import { DatePicker } from 'antd';
import { contextData } from '../context/DataContext';
import moment from 'moment';
import { api } from '../services/Axios';
import { IoMdArrowDropdown } from "react-icons/io";
import dayjs from 'dayjs';
import { reminderStatusArray, statusArray } from '../services/Constants';

const Status = ({ record, open, showModal }) => {

    const { tasksDataCallback, leadsTableDataCallback, leadsTableData, resetFilters, resetFiltersCallback } = useContext(contextData);
    const today = new Date();
    let tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const initial = {
        leadId: record.LID,
        newStatus: record.status,
        newActivityDate: `${today.getDate().toString().padStart(2, '0')}/${(today.getMonth() + 1).toString().padStart(2, '0')}/${today.getFullYear()}`,
        newReminderDate: `${tomorrow.getDate().toString().padStart(2, '0')}/${(tomorrow.getMonth() + 1).toString().padStart(2, '0')}/${tomorrow.getFullYear()}`,
        newRemarks: ""
    }
    const [options, setShowOptions] = useState(false);
    const [updatedData, setUpdatedData] = useState(initial);
    const [reminderDate, setReminderDate] = useState(dayjs(`${tomorrow.getDate().toString().padStart(2, '0')}/${(tomorrow.getMonth() + 1).toString().padStart(2, '0')}/${tomorrow.getFullYear()}`, "DD/MM/YYYY"));
    const [activityDate, setActivityDate] = useState(dayjs(`${today.getDate().toString().padStart(2, '0')}/${(today.getMonth() + 1).toString().padStart(2, '0')}/${today.getFullYear()}`, "DD/MM/YYYY"));

    const form = useRef();

    const handleChange = (e) => {
        e.stopPropagation();
        if (options) {
            setShowOptions(false);
        }
    }

    const handleActivityChange = (date, dateString) => {
        setActivityDate(date);
        setUpdatedData((prevState) => { return { ...prevState, newActivityDate: dateString } });
    }

    const handleReminderChange = (date, dateString) => {
        setReminderDate(date);
        setUpdatedData((prevState) => { return { ...prevState, newReminderDate: dateString } });
    }

    const onSubmitHandler = async () => {
        if (updatedData.newStatus !== record.status || updatedData.newActivityDate !== record.activity_date || updatedData.newReminderDate !== record.scheduled_date || updatedData.newRemarks !== record.remarks) {
            api.post(`${process.env.REACT_APP_API_URL}/update`, updatedData)
                .then((response) => {
                    resetFiltersCallback(!resetFilters);
                    // console.log(response);
                    let newData = [];
                    newData = leadsTableData.filter((element) => element.LID !== record.LID);
                    newData.push(response.data[0]);
                    // console.log(newData)
                    leadsTableDataCallback(newData);
                    tasksDataCallback(newData.filter((element) => ["Incoming", ...reminderStatusArray].includes(element.status)));
                    form.current.reset();
                })
                .catch((error) => {
                    // console.log(error);
                })
        }
        showModal(false);
    };

    const handleCancel = () => {
        setUpdatedData(initial);
        showModal(false);
        form.current.reset();
    };

    const closeClick = () => {
        if (options) {
            setShowOptions(false);
        } else {
            setUpdatedData(initial);
            showModal(false);
            form.current.reset();
        }
    }

    return (
        <div id="modal-wrapper" style={open ? { display: "block", opacity: 1 } : { display: "none", opacity: 0 }} onClick={closeClick}>
            <div className="manual-modal-wrapper">
                <div className='manual-modal' onClick={(e) => handleChange(e)}>
                    <div className="manual-modal-title">
                        <p>Update</p>
                    </div>
                    <hr />
                    <div className="manual-modal-content">
                        <form ref={form}>
                            <div className='form-row'>
                                <div className="label-wrapper">
                                    <label htmlFor="edit-status">Status :</label>
                                </div>
                                <div className='status-dropdown'>
                                    <button type="button" onClick={() => { return setShowOptions(!options) }}>
                                        <span>{updatedData.newStatus}</span>
                                        <IoMdArrowDropdown />
                                    </button>
                                    <ul className='status-options' style={options ? { display: "block" } : { display: "none" }}>
                                        {statusArray.map((data, i) => {
                                            return (
                                                <li key={i} onClick={(e) => { return setUpdatedData((prevState) => { return { ...prevState, newStatus: data } }) }}>{data}</li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className="label-wrapper">
                                    <label htmlFor="edit-activity-date">Activity Date :</label>
                                </div>
                                <DatePicker getPopupContainer={() => document.getElementById('date-picker-container')} id="edit-activity-date" placeholder="Select Date" format='DD/MM/YYYY' onChange={handleActivityChange} disabledDate={(current) => { return current && current > moment().endOf('day') }} value={activityDate} />
                            </div>
                            {reminderStatusArray.includes(updatedData.newStatus) && <div className='form-row'>
                                <div className="label-wrapper">
                                    <label htmlFor="edit-reminder-date">Next Reminder :</label>
                                </div>
                                <DatePicker getPopupContainer={() => document.getElementById('date-picker-container')} id="edit-reminder-date" placeholder="Select Date" format='DD/MM/YYYY' onChange={handleReminderChange} disabledDate={(current) => { return current && current < moment().startOf('day') }} value={reminderDate} />
                            </div>}
                            <div className='form-row'>
                                <div className="label-wrapper">
                                    <label htmlFor="edit-remarks">Remarks :</label>
                                </div>
                                <textarea id="edit-remarks" rows='5' defaultValue={record.remarks} onChange={(e) => { return setUpdatedData((prevState) => { return { ...prevState, newRemarks: e.target.value } }) }}></textarea>
                            </div>
                        </form>
                    </div>
                    <hr />
                    <div className="manual-modal-footer">
                        <button onClick={() => handleCancel()}>Cancel</button>
                        <button onClick={() => onSubmitHandler()}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Status