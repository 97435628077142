import React, { useState, useEffect, useContext } from 'react';
import '../css/AssignmentModal.css';
import { IoMdArrowDropdown } from "react-icons/io";
import { api } from '../services/Axios';
import { contextData } from '../context/DataContext';

let selectedIDs = [];

const Names = (props) => {

    const { subBrokersActive } = useContext(contextData);
    const [checked, setIsChecked] = useState(false);

    const handleCheck = () => {
        if (checked) {
            setIsChecked(false);
            if (selectedIDs.includes(props.SBID)) {
                const index = selectedIDs.indexOf(props.SBID);
                selectedIDs.splice(index, 1);
                // console.log(selectedIDs)
            }
            if ((selectedIDs.length === subBrokersActive.length - 1) && props.selectAll) {
                props.selectAllCallback(false);
            }
        } else {
            setIsChecked(true);
            if (!selectedIDs.includes(props.SBID)) {
                selectedIDs.push(props.SBID);
                // console.log(selectedIDs)
            }
            if ((selectedIDs.length === subBrokersActive.length) && !props.selectAll) {
                props.selectAllCallback(true);
            }
        }
    }

    useEffect(() => {
        if (props.selectAll && !checked) {
            setIsChecked(true);
            if (!selectedIDs.includes(props.SBID)) {
                selectedIDs.push(props.SBID);
                // console.log(selectedIDs)
            }
        }
    }, [props.selectAll])

    useEffect(() => {
        if (selectedIDs.length === 0 && checked) {
            setIsChecked(false);
        }
    }, [props.reset])

    useEffect(() => {
        if (selectedIDs.length === 0) {
            props.checkedCallback(false);
        } else {
            props.checkedCallback(true);
        }
    })

    return (
        <li style={{ display: props.searchFilterFields.some((element) => { return element.SBID === props.SBID }) ? "flex" : "none" }}>
            <input
                type="checkbox"
                id={`broker-id-${props.SBID}`}
                checked={checked}
                value={props.SBID}
                onChange={() => handleCheck()}
            />
            <label htmlFor={`broker-id-${props.SBID}`}>{props.name}</label>
        </li>
    )
}

const AssignmentModal = (props) => {

    const { subBrokersActive, leadsTableDataCallback } = useContext(contextData);
    const [showOptions, setShowOptions] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [selectAll, setSelectAll] = useState(false);
    const [searchFilterFields, setSearchFilterFields] = useState([]);
    const [reset, setReset] = useState(false);
    const [checked, setChecked] = useState(false);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectAll(!selectAll);
            selectedIDs = [];
            // console.log(selectedIDs)
            setReset(!reset);
        } else {
            setSelectAll(!selectAll);
        }
    }

    const selectAllCallback = (value) => {
        setSelectAll(value);
    }

    const handleResetNames = () => {
        selectedIDs = [];
        setSearchString("");
        setSearchFilterFields(subBrokersActive);
        setSelectAll(false);
        setReset(!reset);
    }

    const assignSelection = (mode) => {
        if (mode === "selected" && selectedIDs.length === 0) {
            // pass
        } else {
            props.loading(true);
            const obj = {
                selectedIDs: selectedIDs,
                selectedLeads: props.selectedLeads,
                mode: mode
            }
            api.post(`${process.env.REACT_APP_API_URL}/assign`, obj)
                .then((response) => {
                    // console.log(response);
                    // console.log(selectedIDs);
                    // console.log(props.selectedLeads);
                    leadsTableDataCallback(response.data);
                    handleResetNames();
                    props.resetFilters();
                    props.uncheckSelection();
                    props.closeModal();
                })
                .catch((error) => {
                    // console.log(error);
                })
                .finally(() => {
                    props.loading(false);
                })
        }
    }

    const autoAssign = () => {
        selectedIDs = subBrokersActive.map((d) => {
            return d.SBID;
        })
        assignSelection("auto");
    }

    const closeClick = () => {
        if (showOptions) {
            setShowOptions(false);
        } else {
            props.closeModal();
        }
    }

    const handleClick = (e) => {
        e.stopPropagation();
        if (showOptions) {
            setShowOptions(false);
        }
    }

    const checkedCallback = (value) => {
        setChecked(value);
    }

    useEffect(() => {
        if (searchString.length > 0) {
            let tempArr = [];
            subBrokersActive.filter((d) => {
                if (d.name.toLowerCase().includes(searchString.toLowerCase())) {
                    tempArr.push(d);
                }
                return null;
            });
            setSearchFilterFields(tempArr);
        } else {
            setSearchFilterFields(subBrokersActive);
        }
    }, [searchString])

    useEffect(() => {
        setSearchFilterFields(subBrokersActive);
    }, [])

    return (
        <div id="assignment-modal-wrapper" style={props.open ? { display: "flex", opacity: 1, zIndex: 2 } : { display: "none", opacity: 0 }} onClick={closeClick}>
            <div className='manual-modal' onClick={(e) => handleClick(e)}>
                <div className="manual-modal-content">
                    <div className="wrapper">
                        <button style={showOptions ? { backgroundColor: "#5D70D2", color: "#FFFFFF" } : null} onClick={() => { return setShowOptions(!showOptions) }}>Select Employees<IoMdArrowDropdown /></button>
                        <div className="dropdown-menu" style={{ display: showOptions ? "block" : "none" }} onClick={(e) => { return e.stopPropagation() }}>
                            <div className='dropdown-outer-item-first'>
                                <input
                                    type="text"
                                    placeholder='Search'
                                    value={searchString}
                                    onChange={(e) => { return setSearchString(e.target.value) }}
                                />
                                <div>
                                    <input
                                        type="checkbox"
                                        id="select-all-names"
                                        checked={selectAll}
                                        onChange={() => handleSelectAll()}
                                    />
                                    <label htmlFor="select-all-names">Select All</label>
                                </div>
                            </div>
                            <hr />
                            <ul className='checkbox-container'>
                                {subBrokersActive.map((d, index) => {
                                    return (
                                        <Names
                                            key={index}
                                            selectAll={selectAll}
                                            selectAllCallback={selectAllCallback}
                                            name={d.name}
                                            SBID={d.SBID}
                                            reset={reset}
                                            searchFilterFields={searchFilterFields}
                                            checkedCallback={checkedCallback}
                                        />
                                    )
                                })}
                                <span style={{ display: searchFilterFields.length === 0 ? "block" : "none" }}>Employees Not Found</span>
                            </ul>
                            <hr />
                            <div className='dropdown-outer-item'>
                                <button onClick={() => handleResetNames()} disabled={(!checked && searchString === "") ? true : false}>Clear All</button>
                                <button onClick={() => { return setShowOptions(false) }}>OK</button>
                            </div>
                        </div>

                    </div>
                    <span>You must select one or multiple employees</span>
                </div>
                <hr />
                <div className="manual-modal-footer">
                    <button onClick={autoAssign}>Auto Assign</button>
                    <button onClick={() => assignSelection("selected")}>Assign Selected</button>
                </div>
            </div>
        </div>
    )
}

export default AssignmentModal