import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import TableComp from './TableComp';
import Header from './Header';

const Leads = () => {

  const [loading, setLoading] = useState(false);

  const loadingCallback = (value) => {
    setLoading(value);
  }

  return (
    <>
      <Helmet>
        <title>Brahmastra - Leads</title>
      </Helmet>
      <Header title="Leads" loadingCallback={loadingCallback} />
      <TableComp csvLoading={loading} />
    </>
  )
}

export default Leads