import React, { createContext, useState, useEffect } from 'react'

export const themeContext = createContext()

const Theme = (props) => {

    const [theme, setTheme] = useState('light')

    const switchTheme = () => {
        if (theme === 'dark') {
            localStorage.setItem('theme', 'light')
            setTheme('light')
        } else {
            localStorage.setItem('theme', 'dark')
            setTheme('dark')
        }
    }

    useEffect(() => {
        if (localStorage.getItem('theme') === null || localStorage.getItem('theme') === undefined) {
            const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
            localStorage.setItem('theme', defaultDark ? 'dark' : 'light')
            setTheme(defaultDark ? 'dark' : 'light')
        } else {
            if (localStorage.getItem('theme') === 'dark') {
                setTheme('dark')
            } else {
                setTheme('light')
            }
        }
    }, [])

    return (
        <themeContext.Provider
            value={{ theme, switchTheme }}
        >
            {props.children}
        </themeContext.Provider>
    )
}

export default Theme