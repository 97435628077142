import React, { useState, useEffect, useRef } from 'react';
import '../css/AddEmployee.css';
import Header from './Header';
import { Helmet } from 'react-helmet-async';
import { Alert } from 'react-bootstrap';
import { api } from '../services/Axios';
import { Spin } from 'antd';
import { antIcon } from '../services/Constants';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { sourceArray } from '../services/Constants';

const Sources = ({ identifier, setSourceCallback, data, reset }) => {

  const [isChecked, setIsChecked] = useState(false);

  const handleChange = () => {
    setIsChecked(!isChecked);
    setSourceCallback(data);
  }

  useEffect(() => {
    setIsChecked(false);
  }, [reset])

  return (
    <div className='dropdown-menu-item'>
      <input
        type="checkbox"
        id={`${identifier}-checkbox`}
        checked={isChecked}
        onChange={() => handleChange()}
      />
      <label htmlFor={`${identifier}-checkbox`}>{`${data}`}</label>
    </div>
  )
}

const initial = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  employeeID: "",
  source: []
}

const initialAlert = {
  code: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: ""
}

const AddEmployee = () => {

  const [credentials, setCredentials] = useState(initial);
  const [sourceTitle, setSourceTitle] = useState("Select Sources");
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [variant, setVariant] = useState("");
  const [alert, setAlert] = useState(initialAlert);
  const formRef = useRef();

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const setSource = (value) => {
    if (credentials.source.includes(value)) {
      setCredentials((prev) => ({ ...prev, source: prev.source.filter((item) => item !== value) }));
    } else {
      setCredentials((prev) => ({ ...prev, source: [...prev.source, value] }));
    }
  }

  const handleClearAll = () => {
    setCredentials((prev) => ({ ...prev, source: [] }));
    setSourceTitle("Select Sources");
    setReset(!reset);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      api.post(`${process.env.REACT_APP_API_URL}/add_emp`, credentials)
        .then((res) => {
          if (res.data.message && res.data.message !== "success") {
            setVariant("danger");
            setAlert((prev) => ({ ...prev, code: res.data.message }));
            setTimeout(() => {
              setAlert((prev) => ({ ...prev, code: "" }));
              setVariant("");
            }, 5000)
          } else {
            formRef.current.reset();
            setCredentials(initial);
            setVariant("success");
            setAlert((prev) => ({ ...prev, code: "Account created successfully!" }));
            setTimeout(() => {
              setAlert((prev) => ({ ...prev, code: "" }));
              setVariant("");
            }, 5000)
          }
        })
        .catch((err) => {
          // console.log(err);
          setVariant("danger");
          setAlert((prev) => ({ ...prev, code: "Something went wrong, please try again later!" }));
          setTimeout(() => {
            setAlert((prev) => ({ ...prev, code: "" }));
            setVariant("");
          }, 5000)
        })
        .finally(() => {
          setLoading(false);
        })
    }
  }

  const handleReset = () => {
    setReset(!reset);
    formRef.current.reset();
    setCredentials(initial);
    setAlert(initialAlert);
  }

  const validateForm = () => {
    let flag = false;
    if (credentials.firstName === "") {
      setAlert((prev) => ({ ...prev, firstName: "First Name is required" }));
      flag = true;
    }
    if (credentials.lastName === "") {
      setAlert((prev) => ({ ...prev, lastName: "Last Name is required" }));
      flag = true;
    }
    if (/\s/.test(credentials.lastName)) {
      setAlert((prev) => ({ ...prev, lastName: "Last Name cannot contain spaces" }));
      flag = true;
    }
    if (!/^\w+([\.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(credentials.email)) {
      setAlert((prev) => ({ ...prev, email: "Please enter a valid email address" }));
      flag = true;
    }
    if (credentials.email === "") {
      setAlert((prev) => ({ ...prev, email: "Email is required" }));
      flag = true;
    }
    if (credentials.phone.match(/^[0-9]{10}$/) === null) {
      setAlert((prev) => ({ ...prev, phone: "Please enter a valid 10 digit phone number" }));
      flag = true;
    }
    if (credentials.phone === "") {
      setAlert((prev) => ({ ...prev, phone: "Phone is required" }));
      flag = true;
    }
    if (flag) {
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    if (credentials.firstName !== "") {
      setAlert((prev) => ({ ...prev, firstName: "" }));
    }
    if (credentials.lastName !== "") {
      setAlert((prev) => ({ ...prev, lastName: "" }));
    }
    if (!(/\s/.test(credentials.lastName))) {
      setAlert((prev) => ({ ...prev, lastName: "" }));
    }
    if (/^\w+([\.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(credentials.email)) {
      setAlert((prev) => ({ ...prev, email: "" }));
    }
    if (credentials.email !== "") {
      setAlert((prev) => ({ ...prev, email: "" }));
    }
    if (credentials.phone.match(/^[0-9]{10}$/) !== null) {
      setAlert((prev) => ({ ...prev, phone: "" }));
    }
    if (credentials.phone !== "") {
      setAlert((prev) => ({ ...prev, phone: "" }));
    }
  }, [credentials.firstName, credentials.lastName, credentials.email, credentials.phone]);

  useEffect(() => {
    if (credentials.source.length > 0) {
    setSourceTitle(credentials.source.join(", "));
    } else {
      setSourceTitle("Select Sources");
    }
  }, [credentials.source]);

  return (
    <>
      <Helmet>
        <title>Brahmastra - Add Employee</title>
      </Helmet>
      <Header title="Add Employee" />
      <Spin indicator={antIcon} spinning={loading} size="large">
        <form id="add-employee-form" ref={formRef} autoComplete="on" onSubmit={(e) => handleSubmit(e)}>
          {alert.code && <><Alert variant={variant}>{alert.code}</Alert></>}
          <div>
            <span>
              <label htmlFor="firstName">First Name<span style={{ color: "red" }}> *</span></label><br />
              <input type="text" name="firstName" id="firstName" onChange={(e) => handleChange(e)} /><br />
              {alert.firstName && <Alert variant='danger'>{alert.firstName}</Alert>}
            </span>
            <span>
              <label htmlFor="lastName">Last Name<span style={{ color: "red" }}> *</span></label><br />
              <input type="text" name="lastName" id="lastName" onChange={(e) => handleChange(e)} /><br />
              {alert.lastName && <Alert variant='danger'>{alert.lastName}</Alert>}
            </span>
          </div>
          <div>
            <span>
              <label htmlFor="email">Email<span style={{ color: "red" }}> *</span></label><br />
              <input type="text" name="email" id="email" onChange={(e) => handleChange(e)} /><br />
              {alert.email && <Alert variant='danger'>{alert.email}</Alert>}
            </span>
            <span>
              <label htmlFor="phone">Phone<span style={{ color: "red" }}> *</span></label><br />
              <input type="text" name="phone" id="phone" onChange={(e) => handleChange(e)} /><br />
              {alert.phone && <Alert variant='danger'>{alert.phone}</Alert>}
            </span>
          </div>
          <div>
            <span>
              <label htmlFor="employeeID">Employee ID - <i>if any</i></label><br />
              <input type="text" name="employeeID" id="employeeID" onChange={(e) => handleChange(e)} /><br />
            </span>
            <span>
              <label htmlFor="source">Assign Default Sources</label><br />
              <DropdownButton id="dropdown-basic-button source" title={<span>{sourceTitle}</span>}>
                <div>
                  {sourceArray.map((data, i) => {
                    return (
                      <Sources key={i} identifier={i} data={data} setSourceCallback={setSource} reset={reset} />
                    )
                  })}
                </div>
                <Dropdown.Divider />
                <div className='dropdown-outer-item'>
                    <button onClick={() => handleClearAll()} disabled={!credentials.source.length > 0 ? true : false} >Clear All</button>
                    <Dropdown.Item>
                        <button onClick={() => {return null}}>OK</button>
                    </Dropdown.Item>
                </div>
              </DropdownButton>
            </span>
          </div>
          <div>
            <button type="submit">Add</button>
            <button type="reset" onClick={() => handleReset()}>Reset</button>
          </div>
        </form>
      </Spin>
    </>
  )
}

export default AddEmployee