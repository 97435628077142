import React, { useState, useEffect, useContext } from 'react';
import '../css/Dashboard.css';
import Header from './Header';
import { api } from '../services/Axios';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { contextData } from '../context/DataContext';
import { DatePicker } from 'antd';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { PieChart, Pie, Cell } from 'recharts';
import { Spin } from 'antd';
import { antIcon } from '../services/Constants';
import { Empty } from 'antd';
import { themeContext } from '../context/Theme';

// const data1 = [
//   { name: 'Wakad', Buy: 22, Rent: 3, Resale: 4 },
//   { name: 'Hinjewadi', Buy: 13, Rent: 15, Resale: 6 },
//   { name: 'Swargate', Buy: 44, Rent: 35, Resale: 8 },
//   { name: 'Kharadi', Buy: 35, Rent: 45, Resale: 0 },
//   { name: 'Kothrud', Buy: 62, Rent: 25, Resale: 4 },
//   { name: 'Viman Nagar', Buy: 37, Rent: 17, Resale: 10 },
//   { name: 'Pimpri', Buy: 0, Rent: 32, Resale: 15 },
//   { name: 'Chinchwad', Buy: 19, Rent: 43, Resale: 20 },
// ];

// const data2 = [
//   { name: 'Facebook', value: 400 },
//   { name: 'Google', value: 300 },
//   { name: 'MagicBricks', value: 300 },
//   { name: 'Housing', value: 200 },
//   { name: 'Others', value: 750 },
// ];

const COLORS = ["#5D70D2", "#00C49F", "#FFBB28", "#00e6e6", "#F27B86"];
// const RADIAN = Math.PI / 180;

const DashboardFiltersDropdown = ({ globalReset }) => {

  const { subBrokers, dashboardFiltersData } = useContext(contextData);
  const [searchString, setSearchString] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [searchFilterFields, setSearchFilterFields] = useState(subBrokers);
  const [render, setRender] = useState(false);

  const handleSelect = (value) => {
    if (dashboardFiltersData.employees.includes(value)) {
      const index = dashboardFiltersData.employees.indexOf(value);
      dashboardFiltersData.employees.splice(index, 1);
      if (selectAll) {
        setSelectAll(false);
      } else {
        setRender(!render);
      }
    } else {
      dashboardFiltersData.employees.push(value);
      if (dashboardFiltersData.employees.length === subBrokers.length) {
        setSelectAll(true);
      } else {
        setRender(!render);
      }
    }
  }

  const handleReset = () => {
    dashboardFiltersData.employees = [];
    setSearchString("");
    setSearchFilterFields(subBrokers);
    if (selectAll) {
      setSelectAll(false);
    }
    setRender(!render);
  }

  const handleSelectAll = () => {
    if (selectAll) {
      dashboardFiltersData.employees = [];
      setSelectAll(false);
    }
    else {
      setSelectAll(true);
      dashboardFiltersData.employees = subBrokers.map((element) => element.SBID);
    }
  }

  useEffect(() => {
    if (searchString.length > 0) {
      let tempArr = [];
      subBrokers.filter((d) => {
        if (d.name.toLowerCase().includes(searchString.toLowerCase())) {
          tempArr.push(d);
        }
        return null;
      });
      setSearchFilterFields(tempArr);
    } else {
      setSearchFilterFields(subBrokers);
    }
  }, [searchString])

  useEffect(() => {
    handleReset();
  }, [globalReset])

  useEffect(() => {
    setSearchFilterFields(subBrokers);
  }, [subBrokers])

  return (
    <div className="wrapper">
      <div style={dashboardFiltersData.employees.length > 0 ? { position: "absolute", top: "-15%", right: "-5%", background: "#5D70D2", width: "2vh", height: "2vh", borderRadius: "50%", zIndex: 4 } : null}></div>
      <DropdownButton title="Select Employees">
        <div className='dropdown-outer-item-first'>
          <input
            type="text"
            placeholder='Search'
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
          />
          {searchFilterFields.length > 0 &&
            <div>
              <input
                type="checkbox"
                id="select-all-employees"
                checked={selectAll}
                onChange={() => handleSelectAll()}
              />
              <label htmlFor="select-all-employees">Select All</label>
            </div>}
        </div>
        <Dropdown.Divider />
        <ul className='checkbox-container'>
          {subBrokers.map((d) => {
            return (
              <li key={d.SBID} className='dropdown-menu-item' style={{ display: searchFilterFields.some((element) => { return element.SBID === d.SBID }) ? "flex" : "none" }}>
                <input
                  type="checkbox"
                  id={`${d.SBID}-checkbox`}
                  checked={dashboardFiltersData.employees.includes(d.SBID)}
                  onChange={() => handleSelect(d.SBID)}
                />
                <label htmlFor={`${d.SBID}-checkbox`}>{`${d.name}`}</label>
              </li>
            )
          })}
          <span style={{ display: searchFilterFields.length === 0 ? "block" : "none" }}>
            Employee Not Found
          </span>
        </ul>
        <Dropdown.Divider />
        <div className='dropdown-outer-item'>
          <button onClick={() => handleReset()} disabled={(dashboardFiltersData.employees.length === 0 && searchString === "") ? true : false}>Clear All</button>
          <Dropdown.Item>
            <button onClick={() => { return null }}>OK</button>
          </Dropdown.Item>
        </div>
      </DropdownButton>
    </div>
  )
}

const Dashboard = () => {

  const { group, dashboardFiltersData } = useContext(contextData);
  const { theme } = useContext(themeContext);
  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fromChecked, setFromChecked] = useState(false);
  const [toChecked, setToChecked] = useState(false);
  const [globalReset, setGlobalReset] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const fromDisabledDate = (current) => {
    if (toDate) {
      return current && current > moment(new Date(toDate));
    } else {
      return current && current > moment().endOf('day');
    }
  };

  const toDisabledDate = (current) => {
    if (fromDate) {
      return current && (current < moment(new Date(fromDate)) || current > moment().endOf('day'));
    } else {
      return current && current > moment().endOf('day');
    }
  };

  const onChangeFrom = (date, dateString) => {
    if (!dateString) {
      setFromDate(null);
      dashboardFiltersData.dateStart = "";
      setFromChecked(false);
    } else {
      setFromDate(date);
      dashboardFiltersData.dateStart = dateString;
      setFromChecked(true);
    }
  }

  const onChangeTo = (date, dateString) => {
    if (!dateString) {
      setToDate(null);
      dashboardFiltersData.dateEnd = "";
      setToChecked(false);
    } else {
      setToDate(date);
      dashboardFiltersData.dateEnd = dateString;
      setToChecked(true);
    }
  }

  const resetFilters = () => {
    dashboardFiltersData.dateStart = "";
    dashboardFiltersData.dateEnd = "";
    dashboardFiltersData.employees = [];
    onChangeFrom(null, null);
    onChangeTo(null, null);
    setGlobalReset(!globalReset);
    getDashboardData();
  }

  const applyFilters = async () => {
    try {
      setLoading(true);
      const response = await api.post(`${process.env.REACT_APP_API_URL}/get_count`, dashboardFiltersData);
      // console.log(dashboardFiltersData);
      // console.log(response.data);
      setApiData(response.data);
    } catch (err) {
      // console.log(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  const getDashboardData = async () => {
    try {
      setLoading(true);
      const response = await api.get(`${process.env.REACT_APP_API_URL}/get_count`);
      // console.log(response.data)
      setApiData(response.data);
    } catch (err) {
      // console.log(err)
      setError(err.message);
      setApiData(null);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getDashboardData();
  }, [])

  return (
    <>
      <Helmet>
        <title>Brahmastra - Dashboard</title>
      </Helmet>
      <Header title="Dashboard" dashboardReportsData={apiData} />
      <Spin indicator={antIcon} spinning={loading} size="large" style={{ height: "100vmax" }}>
        {!error && apiData &&
          <div id="dashboard">
            <div className='filters-panel'>
              <div className='filters'>
                {group === "admin" && <DashboardFiltersDropdown globalReset={globalReset} />}
                <div style={{ position: "relative" }}>
                  <div style={(fromChecked) ? { position: "absolute", top: "-15%", right: "-5%", background: "#5D70D2", width: "2vh", height: "2vh", borderRadius: "50%", zIndex: 4 } : null}></div>
                  <DatePicker getPopupContainer={() => document.getElementById('date-picker-container')} placeholder="From" format='DD/MM/YYYY' onChange={onChangeFrom} value={fromDate} disabledDate={fromDisabledDate}/>
                </div>
                <div style= {{ position: "relative" }}>
                  <div style={(toChecked) ? { position: "absolute", top: "-15%", right: "-5%", background: "#5D70D2", width: "2vh", height: "2vh", borderRadius: "50%", zIndex: 4 } : null}></div>
                  <DatePicker getPopupContainer={() => document.getElementById('date-picker-container')} placeholder="To" format='DD/MM/YYYY' onChange={onChangeTo} value={toDate} disabledDate={toDisabledDate} />
                </div>
              </div>
              <div className='filter-actions'>
                <button type="reset" onClick={resetFilters} >Reset</button>
                <button type="button" onClick={applyFilters}>Apply</button>
              </div>
            </div>
            <div className='dashboard-cards-track'>
              <div className="dashboard-card" style={{ border: theme === "light" ? "1px solid #5D70D2" : "none" }}>
                {apiData.statuses["Incoming"] + apiData.statuses["Interested"] + apiData.statuses["Call Not Received"] + apiData.statuses["Call Back Later"] + apiData.statuses["Site-Visit Scheduled"] + apiData.statuses["Site-Visit Rescheduled"] + apiData.statuses["Site-Visit Done"] + apiData.statuses["Booking Done"] + apiData.statuses["Plan Postponed"] + apiData.statuses["Not Interested"]}
                <span>Total Leads</span>
              </div>
              <div className="dashboard-card" style={{ border: theme === "light" ? "1px solid #5D70D2" : "none" }}>
                {apiData.statuses["Incoming"]}
                <span>Incoming</span>
              </div>
              <div className="dashboard-card" style={{ border: theme === "light" ? "1px solid #5D70D2" : "none" }}>
                {apiData.statuses["Interested"]}
                <span>Interested</span>
              </div>
              <div className="dashboard-card" style={{ border: theme === "light" ? "1px solid #5D70D2" : "none" }}>
                {apiData.statuses["Call Not Received"]}
                <span>Call Not Received</span>
              </div>
              <div className="dashboard-card" style={{ border: theme === "light" ? "1px solid #5D70D2" : "none" }}>
                {apiData.statuses["Call Back Later"]}
                <span>Call Back Later</span>
              </div>
              <div className="dashboard-card" style={{ border: theme === "light" ? "1px solid #5D70D2" : "none" }}>
                {apiData.statuses["Site-Visit Scheduled"]}
                <span>Site-Visit Scheduled</span>
              </div>
              <div className="dashboard-card" style={{ border: theme === "light" ? "1px solid #5D70D2" : "none" }}>
                {apiData.statuses["Site-Visit Rescheduled"]}
                <span>Site-Visit Rescheduled</span>
              </div>
              <div className="dashboard-card" style={{ border: theme === "light" ? "1px solid #5D70D2" : "none" }}>
                {apiData.statuses["Site-Visit Done"]}
                <span>Site-Visit Done</span>
              </div>
              <div className="dashboard-card" style={{ border: theme === "light" ? "1px solid #5D70D2" : "none" }}>
                {apiData.statuses["Booking Done"]}
                <span>Booking Done</span>
              </div>
              <div className="dashboard-card" style={{ border: theme === "light" ? "1px solid #5D70D2" : "none" }}>
                {apiData.statuses["Plan Postponed"]}
                <span>Plan Postponed</span>
              </div>
              <div className="dashboard-card" style={{ border: theme === "light" ? "1px solid #5D70D2" : "none" }}>
                {apiData.statuses["Not Interested"]}
                <span>Not Interested</span>
              </div>
            </div>
            <div className="dashboard-reports">
              <div className="bar-chart">
                {apiData.areawise_graph.length > 0 ?
                  <>
                    <div>Number of Leads by Deal Type of different Configurations</div>
                    <BarChart
                      width={675}
                      height={275}
                      data={apiData.areawise_graph}
                      margin={{ top: 35, right: 30, bottom: 20, left: 0 }}
                      barSize={10}
                    >
                      <XAxis dataKey="name" tick={{ fill: theme === "light" ? "#000" : "#FFFFFF" }} tickLine={false} axisLine={{ stroke: theme === "light" ? "#000" : "#FFFFFF" }} />
                      <YAxis tick={{ fill: theme === "light" ? "#000" : "#FFFFFF" }} tickLine={false} axisLine={{ stroke: theme === "light" ? "#000" : "#FFFFFF" }} />
                      <Tooltip cursor={false} />
                      <Legend />
                      <Bar dataKey="Buy" stackId="a" fill="#5D70D2" />
                      <Bar dataKey="Rent" stackId="a" fill="#FFBB28" />
                      <Bar dataKey="Resale" stackId="a" fill="#00e6e6" />
                      <Bar dataKey="Other" stackId="a" fill="#F27B86" />
                    </BarChart>
                  </> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              </div>
              <div className="pie-chart">
                {apiData.sources.length > 0 ?
                  <>
                    <div>Source-wise Leads</div>
                    <PieChart width={500} height={275}>
                      <Legend layout="vertical" verticalAlign="middle" align="right" />
                      <Pie
                        data={apiData.sources}
                        labelLine={false}
                        outerRadius={100}
                        fill="transparent"
                        dataKey="value"
                        stroke='none'
                      >
                        {apiData.sources.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </> :
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
              </div>
            </div>
          </div>}
      </Spin>
    </>
  )
}

export default Dashboard;