import React, { useState, useEffect, useContext } from 'react';
import '../css/MyProfile.css';
import Header from './Header';
import { Helmet } from 'react-helmet-async';
import { api } from '../services/Axios';
import { Spin, Radio, ConfigProvider } from 'antd';
import { antIcon } from '../services/Constants';
import { contextData } from '../context/DataContext';
import { themeContext } from '../context/Theme';

const initialError = {
  firstName: "",
  lastName: "",
  phone: "",
  reraId: "",
}

const options = [
  { label: 'Automated Emails On', value: true },
  { label: 'Automated Emails Off', value: false },
];

const MyProfile = () => {

  const { group, profile, profileCallback } = useContext(contextData);
  const { theme } = useContext(themeContext);
  const [user, setUser] = useState(profile);
  const [editActive, setEditActive] = useState(false);
  const [error, setError] = useState(initialError);
  const [loading, setLoading] = useState(false);

  const style = {
    borderBottom: !editActive ? 'none' : `1px solid ${theme === 'dark' ? '#000000' : '#D9D9D9'}`,
  }

  const toggleEmail = (e) => {
    // console.log(e.target.value);
    setLoading(true);
    api.get(`${process.env.REACT_APP_API_URL}/toggle_email`)
      .then((response) => {
        // console.log(response.data);
        profileCallback(response.data);
        setUser(response.data);
      })
      .catch((error) => {
        // console.log(error);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  }

  const handleCancel = () => {
    setEditActive(false);
    setError(initialError);
    setUser(profile);
  }

  const handleSubmit = () => {
    if (validate()) {
      setLoading(true);
      api.post(`${process.env.REACT_APP_API_URL}/update_profile`, user)
        .then((response) => {
          profileCallback(response.data);
          setUser(response.data);
        })
        .finally(() => {
          setEditActive(false);
          setLoading(false);
        })
    }
  }

  const validate = () => {
    let flag = false;
    if (user.firstName === "") {
      setError((prev) => ({ ...prev, firstName: "First Name cannot be blank" }));
      flag = true;
    }
    if (user.lastName === "") {
      setError((prev) => ({ ...prev, lastName: "Last Name cannot be blank" }));
      flag = true;
    }
    if (/\s/.test(user.lastName)) {
      setError((prev) => ({ ...prev, lastName: "Last Name cannot contain spaces" }));
      flag = true;
    }
    if (!(/^[0-9]{10}$/.test(user.phone))) {
      setError((prev) => ({ ...prev, phone: "Please enter a valid 10 digit phone number" }));
      flag = true;
    }
    if (user.phone === "") {
      setError((prev) => ({ ...prev, phone: "Phone Number cannot be blank" }));
      flag = true;
    }
    if (user.reraId && (user.reraId.match(/^[0-9a-zA-Z]+$/) === null)) {
      setError((prev) => ({ ...prev, reraId: "Please enter a valid (alphanumeric) RERA ID" }));
      flag = true;
    }
    if (flag) {
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    // if (user !== initial) {
    if (user.firstName !== "") {
      setError((prev) => ({ ...prev, firstName: "" }));
    }
    if (user.lastName !== "") {
      setError((prev) => ({ ...prev, lastName: "" }));
    }
    if (!(/\s/.test(user.lastName))) {
      setError((prev) => ({ ...prev, lastName: "" }));
    }
    if (/^[0-9]{10}$/.test(user.phone)) {
      setError((prev) => ({ ...prev, phone: "" }));
    }
    if (user.phone !== "") {
      setError((prev) => ({ ...prev, phone: "" }));
    }
    if (!user.reraId || (user.reraId && user.reraId.match(/^[0-9a-zA-Z]+$/) !== null)) {
      setError((prev) => ({ ...prev, reraId: "" }));
    }
    // }
  }, [user.firstName, user.lastName, user.phone, user.reraId]);

  useEffect(() => {
    setUser(profile);
  }, [profile])

  return (
    <>
      <Helmet>
        <title>Brahmastra - My Profile</title>
      </Helmet>
      <Header title="My Profile" />
      <Spin indicator={antIcon} spinning={loading} size="large" >
        <div id='myprofile'>
          <h2>{profile.firstName} {profile.lastName}</h2>
          <div className="info-container">
            <div className="info-row">
              <span><span style={{ color: '#5D70D2', fontWeight: 500 }}>{user.group === "admin" ? "User" : "Employee"} ID:</span> <i>{user.userId}</i></span>
              {group === "employee" ?
              <span className='name-initial'>{profile.firstName && profile.firstName[0].toUpperCase()}</span>
              :
              <span className='toggle-switch'>
                <ConfigProvider
                  theme={{
                    token: {
                      "colorPrimary": "#5d70d2",
                      "colorPrimaryHover": "#5d70d2",
                    },
                  }}
                >
                  <Radio.Group defaultValue={profile.email_toggle === 0 ? "on" : "off"} buttonStyle="solid" size='large' onChange={toggleEmail}>
                    <Radio.Button value="on">Automated Emails On</Radio.Button>
                    <Radio.Button value="off">Automated Emails Off</Radio.Button>
                  </Radio.Group>
                </ConfigProvider>
              </span>}
            </div>
            <div className="info-row">
              <form>
                <div className='info-section'>
                  <div className='section-title'>Personal</div>
                  <div>
                    <span>
                      <label htmlFor='firstName'>First Name</label><br />
                      <input type="text" name="firstName" id='firstName' value={user.firstName} onChange={handleChange} style={style} disabled={editActive ? false : true} /><br />
                      {error.firstName && <p className='error-prompt'>{error.firstName}</p>}
                    </span>
                    <span>
                      <label htmlFor='lastName'>Last Name</label><br />
                      <input type="text" name="lastName" id='lastName' value={user.lastName} onChange={handleChange} style={style} disabled={editActive ? false : true} /><br />
                      {error.lastName && <p className='error-prompt'>{error.lastName}</p>}
                    </span>
                  </div>
                  {group !== "employee" &&
                    <div>
                      <label htmlFor='reraId'>RERA ID</label><br />
                      <input type="text" name="reraId" id='reraId' value={user.reraId} onChange={handleChange} style={style} disabled={editActive ? false : true} /><br />
                      {error.reraId && <p className='error-prompt'>{error.reraId}</p>}
                    </div>}
                </div>
                <div className='info-section'>
                  <div className='section-title'>Contact</div>
                  <div>
                    <span>
                      <label htmlFor='phone'>Phone</label><br />
                      <input type="text" name="phone" id='phone' value={user.phone} onChange={handleChange} style={style} disabled={editActive ? false : true} /><br />
                      {error.phone && <p className='error-prompt'>{error.phone}</p>}
                    </span>
                    <span>
                      <label htmlFor='email'>Email</label><br />
                      <div id='email'>{user.email}</div>
                    </span>
                  </div>
                </div>
                <div className='info-section'>
                  <div className='section-title'>Organization</div>
                  <div>
                    <span>
                      <label htmlFor='companyName'>Name</label><br />
                      <div id='companyName'>{user.companyName}</div>
                    </span>
                    <span>
                      <label htmlFor='companyAddress'>Address</label><br />
                      <div id='companyAddress'>{user.companyAddress}</div>
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div className="button-row">
              <div className='edit-profile' style={{ display: editActive ? "block" : "none" }}>
                <button type='button' onClick={handleCancel}>Cancel</button>
                <button type='button' onClick={handleSubmit}>Save</button>
              </div>
              <div className='edit-button-bar' style={{ display: !editActive ? "block" : "none" }}>
                <button type='button' onClick={() => setEditActive(true)}>Edit</button>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  )
}

export default MyProfile