import React, { useState, useEffect, useContext } from "react";
import { contextData } from "../context/DataContext";
import { api } from "../services/Axios";
import { checkToken, getToken, login, redirectToLogin } from "../services/Auth";

// HOC that checks for the presence of the grant code and id token
// and redirects the user to the sign-in page if either is missing
const WithAuth = (Component) => {
  const NestedComponent = () => {

    const { groupCallback, profileCallback, subBrokersCallback, dashboardFilterFields } = useContext(contextData);
    const [asyncData, setAsyncData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

      const setInitialData = async () => {
        try {
          const response = await api.get(`${process.env.REACT_APP_API_URL}/get_profile`)
          if (response.data) {
            profileCallback(response.data)
            groupCallback(response.data.group)
            if (response.data.group === "admin") {
              try {
                const res = await api.get(`${process.env.REACT_APP_API_URL}/get?table=Subbroker`)
                subBrokersCallback(res.data)
                // console.log("subBrokers just set")
              } catch (err) {
                // console.log(err)
              }
            }
          }
        } catch (err) {
          // console.log(err);
          profileCallback({})
          groupCallback("")
          subBrokersCallback([])
          dashboardFilterFields.employees = []
        }
      };

      const func = async () => {
        const token = getToken();
        const code = new URLSearchParams(window.location.search).get("code");
        if (token) {
          const alreadyLoggedIn = await checkToken(token);
          if (alreadyLoggedIn) {
            setAsyncData(true);
            setInitialData();
            setIsLoading(false);
            return null;
          }
        } else if (code) {
          const isLoggedIn = await login(code);
          if (isLoggedIn) {
            setAsyncData(true);
            setInitialData();
            setIsLoading(false);
            return null;
          }
        }
        setIsLoading(false);
        return null;
      };

      func();
    }, []);

    if (isLoading) {
      return null;
    } else {
      return asyncData ? <Component /> : redirectToLogin();
    }
  };

  return NestedComponent;
};

export default WithAuth;
