import React, { useContext, useEffect, useState } from 'react';
import { contextData } from '../context/DataContext';
import { DatePicker } from 'antd';
import AssignmentModal from './AssignmentModal';
import moment from 'moment';
import FilterDropdown from './FilterDropdown';
import '../css/Filters.css';
import { api } from '../services/Axios';

const initialFilterObject = {
    dateStart: "",
    dateEnd: "",
    names: [],
    mobiles: [],
    source: [],
    brokerSources: [],
    propertyNames: [],
    propertyAreas: [],
    propertyTypes: [],
    dealTypes: [],
    statuses: [],
    calenderDate: "",
    assignedTo: []
}

const Filters = ({ filters, loadingCallback, errorCallback, selectedLeads, uncheckSelection, getLeads }) => {

    const { group, leadsTableDataCallback, resetFilters } = useContext(contextData);
    const [open, setOpen] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [calendarDate, setCalendarDate] = useState(null);
    const [globalReset, setGlobalReset] = useState(false);
    const [alert, setAlert] = useState(false);
    const [filtersObject, setFiltersObject] = useState(initialFilterObject);

    const fromDisabledDate = (current) => {
        if (toDate) {
            return current && current > moment(new Date(toDate));
        } else {
            return current && current > moment().endOf('day');
        }
    };

    const toDisabledDate = (current) => {
        if (fromDate) {
            return current && (current < moment(new Date(fromDate)) || current > moment().endOf('day'));
        } else {
            return current && current > moment().endOf('day');
        }
    };

    const showAlert = () => {
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
        }, 1500)
    };

    const onChangeFrom = (date, dateString) => {
        if (!dateString) {
            setFromDate(null);
            setFiltersObject((filtersObject) => ({ ...filtersObject, dateStart: "" }));
        } else {
            setFromDate(date);
            setFiltersObject((filtersObject) => ({ ...filtersObject, dateStart: dateString }));
        }
    };

    const onChangeTo = (date, dateString) => {
        if (!dateString) {
            setToDate(null);
            setFiltersObject((filtersObject) => ({ ...filtersObject, dateEnd: "" }));
        } else {
            setToDate(date);
            setFiltersObject((filtersObject) => ({ ...filtersObject, dateEnd: dateString }));
        }
    };

    const onChangeDate = (date, dateString) => {
        if (!dateString) {
            setCalendarDate(null);
            setFiltersObject((filtersObject) => ({ ...filtersObject, calenderDate: "" }));
        } else {
            setCalendarDate(date);
            setFiltersObject((filtersObject) => ({ ...filtersObject, calenderDate: dateString }));
        }
    };

    const addToFilterCallback = (field, value) => {
        setFiltersObject((filtersObject) => ({ ...filtersObject, [field]: [...filtersObject[field], value] }));
    }

    const removeFromFilterCallback = (field, value) => {
        setFiltersObject((filtersObject) => ({ ...filtersObject, [field]: filtersObject[field].filter(item => item !== value) }));
    }

    const resetFilterField = (field) => {
        setFiltersObject((filtersObject) => ({ ...filtersObject, [field]: [] }));
    }

    const copyAllFilters = (field) => {
        if (field === "assignedTo") {
            setFiltersObject((filtersObject) => ({ ...filtersObject, assignedTo: filters.assignedTo.map(item => item.SBID) }));
        } else {
            setFiltersObject((filtersObject) => ({ ...filtersObject, [field]: filters[field] }));
        }
    }

    const handleFilters = async () => {
        try {
            loadingCallback(true);
            const response = await api.post(`${process.env.REACT_APP_API_URL}/filter`, filtersObject);
            leadsTableDataCallback(response.data);
        } catch (err) {
            errorCallback(err.message);
        } finally {
            loadingCallback(false);
        }
    }

    const handleReset = () => {
        setFiltersObject(initialFilterObject);
        setFromDate(null);
        setToDate(null);
        setCalendarDate(null);
        setGlobalReset(!globalReset);
        uncheckSelection();
        getLeads();
    }

    const closeModal = () => {
        setOpen(false);
    }

    const handleAssignButton = () => {
        if (selectedLeads.length === 0) {
            showAlert();
        } else {
            setOpen(true);
        }
    }

    useEffect(() => {
        handleReset();
    }, [resetFilters])

    return (
        <>
            <div className='filters'>
                <div className='filter-selectors'>
                    <FilterDropdown
                        field="names"
                        title="Name"
                        reset={globalReset}
                        addToFilter={addToFilterCallback}
                        removeFromFilter={removeFromFilterCallback}
                        resetFilterField={resetFilterField}
                        copyAllFilters={copyAllFilters}
                        filters={filters}
                        filtersObject={filtersObject}
                    />
                    <FilterDropdown
                        field="mobiles"
                        title="Mobile"
                        reset={globalReset}
                        addToFilter={addToFilterCallback}
                        removeFromFilter={removeFromFilterCallback}
                        resetFilterField={resetFilterField}
                        copyAllFilters={copyAllFilters}
                        filters={filters}
                        filtersObject={filtersObject}
                    />
                    <FilterDropdown
                        field="source"
                        title="Source"
                        reset={globalReset}
                        addToFilter={addToFilterCallback}
                        removeFromFilter={removeFromFilterCallback}
                        resetFilterField={resetFilterField}
                        copyAllFilters={copyAllFilters}
                        filters={filters}
                        filtersObject={filtersObject}
                    />
                    {group !== "individual" && <FilterDropdown
                        field="brokerSources"
                        title="Broker Sources"
                        reset={globalReset}
                        addToFilter={addToFilterCallback}
                        removeFromFilter={removeFromFilterCallback}
                        resetFilterField={resetFilterField}
                        copyAllFilters={copyAllFilters}
                        filters={filters}
                        filtersObject={filtersObject}
                    />}
                    <FilterDropdown
                        field="propertyNames"
                        title="Project"
                        reset={globalReset}
                        addToFilter={addToFilterCallback}
                        removeFromFilter={removeFromFilterCallback}
                        resetFilterField={resetFilterField}
                        copyAllFilters={copyAllFilters}
                        filters={filters}
                        filtersObject={filtersObject}
                    />
                    <FilterDropdown
                        field="propertyAreas"
                        title="Location"
                        reset={globalReset}
                        addToFilter={addToFilterCallback}
                        removeFromFilter={removeFromFilterCallback}
                        resetFilterField={resetFilterField}
                        copyAllFilters={copyAllFilters}
                        filters={filters}
                        filtersObject={filtersObject}
                    />
                    <FilterDropdown
                        field="propertyTypes"
                        title="Configuration"
                        reset={globalReset}
                        addToFilter={addToFilterCallback}
                        removeFromFilter={removeFromFilterCallback}
                        resetFilterField={resetFilterField}
                        copyAllFilters={copyAllFilters}
                        filters={filters}
                        filtersObject={filtersObject}
                    />
                    <FilterDropdown
                        field="dealTypes"
                        title="Deal Type"
                        reset={globalReset}
                        addToFilter={addToFilterCallback}
                        removeFromFilter={removeFromFilterCallback}
                        resetFilterField={resetFilterField}
                        copyAllFilters={copyAllFilters}
                        filters={filters}
                        filtersObject={filtersObject}
                    />
                    <FilterDropdown
                        field="statuses"
                        title="Status"
                        reset={globalReset}
                        addToFilter={addToFilterCallback}
                        removeFromFilter={removeFromFilterCallback}
                        resetFilterField={resetFilterField}
                        copyAllFilters={copyAllFilters}
                        filters={filters}
                        filtersObject={filtersObject}
                    />
                    {group === "admin" &&
                        <FilterDropdown
                            field="assignedTo"
                            title="Assigned To"
                            reset={globalReset}
                            addToFilter={addToFilterCallback}
                            removeFromFilter={removeFromFilterCallback}
                            resetFilterField={resetFilterField}
                            copyAllFilters={copyAllFilters}
                            filters={filters}
                            filtersObject={filtersObject}
                        />}
                    <div style={{ position: "relative" }} className="date-button-container">
                        <div style={filtersObject.calenderDate !== "" ? { position: "absolute", top: "-15%", right: "-5%", background: "#5D70D2", width: "2vh", height: "2vh", borderRadius: "50%", zIndex: 4 } : null}></div>
                        <DatePicker getPopupContainer={() => document.getElementById('date-picker-container')} placeholder="Reminder" format='DD/MM/YYYY' onChange={onChangeDate} value={calendarDate} />
                    </div>
                </div>
                <div className='filter-buttons'>
                    {<div className='filter-selectors'>
                        <div style={{ position: "relative", marginRight: "1vw" }} className="date-button-container">
                            <div style={filtersObject.dateStart !== "" ? { position: "absolute", top: "-15%", right: "-5%", background: "#5D70D2", width: "2vh", height: "2vh", borderRadius: "50%", zIndex: 4 } : null}></div>
                            <DatePicker getPopupContainer={() => document.getElementById('date-picker-container')} placeholder="From" format='DD/MM/YYYY' onChange={onChangeFrom} value={fromDate} disabledDate={fromDisabledDate} />
                        </div>
                        <div style={{ position: "relative" }} className="date-button-container">
                            <div style={filtersObject.dateEnd !== "" ? { position: "absolute", top: "-15%", right: "-5%", background: "#5D70D2", width: "2vh", height: "2vh", borderRadius: "50%", zIndex: 4 } : null}></div>
                            <DatePicker getPopupContainer={() => document.getElementById('date-picker-container')} placeholder="To" format='DD/MM/YYYY' onChange={onChangeTo} value={toDate} disabledDate={toDisabledDate} />
                        </div>
                    </div>}
                    {alert && <span>Please select at least one lead!</span>}
                    <div className='filter-buttons-inner'>
                        {group === "admin" &&
                            <>
                                <button type="button" onClick={handleAssignButton} >Assign Leads</button>
                                <AssignmentModal open={open} closeModal={closeModal} selectedLeads={selectedLeads} uncheckSelection={uncheckSelection} loading={loadingCallback} resetFilters={handleReset} />
                            </>
                        }
                        <button type="reset" onClick={handleReset} >Reset</button>
                        <button type="button" onClick={handleFilters}>Apply</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Filters