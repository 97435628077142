import React, { useContext } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router';
import { contextData } from '../context/DataContext';

const LeadDetails = () => {

    const { leadsTableData } = useContext(contextData)
    const location = useLocation();

    return (
        <div id='lead-details'>
            <h3>{leadsTableData.filter((element) => location.state.LID === element.LID )[0].status}</h3>
            <div>
                <div className="info-row">
                    <span>Arrived On</span>
                    <span>{moment(leadsTableData.filter((element) => location.state.LID === element.LID )[0].lead_date).format('DD/MM/YYYY hh:mm a')}</span>
                </div>
                <div className="info-row">
                    <span>Name</span>
                    <span>{leadsTableData.filter((element) => location.state.LID === element.LID )[0].full_name}</span>
                </div>
                <div className="info-row">
                    <span>Email</span>
                    <span>{leadsTableData.filter((element) => location.state.LID === element.LID )[0].email}</span>
                </div>
                <div className="info-row">
                    <span>Phone</span>
                    <span>{leadsTableData.filter((element) => location.state.LID === element.LID )[0].phone}</span>
                </div>
                <div className="info-row">
                    <span>Address</span>
                    <span>{leadsTableData.filter((element) => location.state.LID === element.LID )[0].leadAddress}</span>
                </div>
                <div className="info-row">
                    <span>City</span>
                    <span>{leadsTableData.filter((element) => location.state.LID === element.LID )[0].leadCity}</span>
                </div>
                <div className="info-row">
                    <span>Source</span>
                    <span>{leadsTableData.filter((element) => location.state.LID === element.LID )[0].source.join(", ")}</span>
                </div>
                <div className="info-row">
                    <span>Last Remarks</span>
                    <span>{leadsTableData.filter((element) => location.state.LID === element.LID )[0].remarks}</span>
                </div>
                <div className="info-row">
                    <span>Last Activity On</span>
                    <span>{moment(leadsTableData.filter((element) => location.state.LID === element.LID )[0].activity).format('DD/MM/YYYY hh:mm a')}</span>
                </div>
            </div>
        </div>
    )
}

export default LeadDetails