// test comment
import React, { useContext } from 'react';
import "./App.css";
import "./css/AntDatePicker.css";
import 'antd/dist/reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { contextData } from './context/DataContext';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { HelmetProvider } from 'react-helmet-async';
import dayjs from 'dayjs';
import 'dayjs/locale/en-in';
import moment from 'moment';
import mobilePng from './img/brahmastra.png';
import splash from './img/splash.png';
import Navbar from "./components/Navbar";
import Dashboard from "./components/Dashboard";
import Leads from "./components/Leads";
import LeadProfile from './components/LeadProfile';
import MyProfile from './components/MyProfile';
import ManageEmployees from "./components/ManageEmployees";
import Tasks from './components/Tasks';
import LeadActivities from './components/LeadActivities';
import LeadDetails from './components/LeadDetails';
import AddBrokerSource from './components/AddBrokerSource';
import AddLead from './components/AddLead';
import AddEmployee from './components/AddEmployee';
// import FacebookMapping from './components/FacebookMapping';
import NoMatch from './components/NoMatch';
// import TermsAndConditions from './components/TermsAndConditions';
// import PrivacyPolicy from './components/PrivacyPolicy';
// test comment

dayjs.locale('en-in');
moment.locale("en-in");

const App = () => {

  const { group } = useContext(contextData)
  const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' })
  const isTablet = useMediaQuery({ query: '(min-width: 768px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  // const [theme, setTheme] = useState('');

  return (
    <>
      {isDesktop || (isTablet && !isPortrait) ?
        <>
          <HelmetProvider>
            <Router>
              <Routes>
                {/* <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}
                {/* <Route path="/not-found" element={<NoMatch />} /> */}
                <Route path="/" element={<Navbar />} >
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/leads" element={<Leads />} />
                  <Route path="/leads/:name" element={<LeadProfile />}>
                    <Route index element={<LeadDetails />} />
                    <Route path="details" element={<LeadDetails />} />
                    <Route path="activities" element={<LeadActivities />} />
                    <Route path="*" element={<NoMatch />} />
                  </Route>
                  <Route path="/tasks" element={<Tasks />} />
                  <Route path="/add-lead" element={<AddLead />} />
                  {group !== "individual" && <Route path="/add-broker-source" element={<AddBrokerSource />} />}
                  {group === "admin" &&
                    <>
                      <Route path="/add-employee" element={<AddEmployee />} />
                      <Route path="/manage-employees" element={<ManageEmployees />} />
                    </>}
                  {/* <Route path="/facebook-leads" element={<FacebookMapping />} /> */}
                  <Route path="/profile" element={<MyProfile />} />
                  <Route path="*" element={<NoMatch />} />
                </Route>
              </Routes>
            </Router>
          </HelmetProvider>
        </>
        :
        isTablet && isPortrait ?
          <div className='rotate-device'>
            <div>
              <div></div>
            </div>
            <p>Please rotate your device to landscape mode</p>
          </div>
          :
          <div className="mobile">
            <img src={splash} alt="real estate scene" />
            <img src={mobilePng} alt="brahmastra - manifesting realty" />
            <a href='https://play.google.com/store/apps/details?id=com.pinaka.brahmastra&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
              <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
            </a>
          </div>}
    </>
  );
}
export default App;
