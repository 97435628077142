import { createContext, useState } from "react";
import Whatsapp from '../img/whatsapp.webp';
import { Tooltip } from "antd";
import moment from 'moment';
import Status from "../components/Status";
import { EditOutlined, MailOutlined } from "@ant-design/icons";

const StatusCell = ({ status, record, ModalFlagCallback }) => {

  const [open, setOpen] = useState(false);

  const showModal = (value) => {
    setOpen(value);
    ModalFlagCallback(value);
  }

  return (
    <div className="status-cell">
      <p>{status}</p>
      <EditOutlined style={{ color: "#5D70D2" }} onClick={(e) => { e.preventDefault(); showModal(true); }} />
      <Status record={record} open={open} showModal={showModal} />
    </div>
  )
}

export const contextData = createContext();

const DataContext = (props) => {

  const [group, setGroup] = useState("");
  const [profile, setProfile] = useState({});
  const [subBrokers, setSubBrokers] = useState([]);
  const [subBrokersActive, setSubBrokersActive] = useState([]);
  const [leadsTableData, setLeadsTableData] = useState([]);
  const [tasksData, setTasksData] = useState([]);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [resetFilters, setResetFilters] = useState(false);

  const tasksDataCallback = (value) => {
    setTasksData(value);
  }

  const leadsTableDataCallback = (value) => {
    setLeadsTableData(value.sort((a, b) => {
      const dateComparison = new Date(b.lead_date) - new Date(a.lead_date);
      if (dateComparison === 0) {
        return b.LID - a.LID;
      }
      return dateComparison;
    }));
  }

  const groupCallback = (value) => {
    if (value) {
      setGroup(value);
      // console.log(value)
    }
  }

  const profileCallback = (value) => {
    setProfile(value);
  }

  const subBrokersCallback = (value) => {
    setSubBrokers(value);
    setSubBrokersActive(value.filter((element) => element.active === "Active"));
  }

  const ModalFlagCallback = (value) => {
    setIsStatusModalOpen(value);
  }

  const toggleTableLoading = (value) => {
    setTableLoading(value);
  }

  const resetFiltersCallback = (value) => {
    setResetFilters(value);
  }

  const userColumns = [
    {
      title: 'Date',
      dataIndex: 'lead_date',
      align: 'center',
      sorter: (a, b) => {
        const dateComparison = new Date(b.lead_date) - new Date(a.lead_date);
        if (dateComparison === 0) {
          return b.LID - a.LID;
        }
        return dateComparison;
      },
      // width: '7.2%',
      render: (lead_date) => (
        moment(lead_date).format('DD/MM/YYYY hh:mm a')
      )
    },
    {
      title: 'Name',
      dataIndex: 'full_name',
      ellipsis: {
        showTitle: false,
      },
      render: full_name => (
        <Tooltip placement="left" title={full_name}>
          {full_name}
        </Tooltip>
      ),
      // width: '9.09%'
    },
    {
      title: 'Mobile',
      dataIndex: 'phone',
      align: 'center'
      // width: '8%'
    },
    {
      title: 'Source',
      dataIndex: 'source',
      ellipsis: {
        showTitle: false,
      },
      render: source => (
        <Tooltip placement="left" title={source.join(", ")}>
          {source.join(", ")}
        </Tooltip>
      )
      // width: '9.09%'
    },
    {
      title: 'Project',
      dataIndex: 'interested_property',
      ellipsis: {
        showTitle: false,
      },
      render: interested_property => (
        <Tooltip placement="left" title={interested_property}>
          {interested_property}
        </Tooltip>
      ),
      // width: '9.3%'
    },
    {
      title: 'Location',
      dataIndex: 'property_area',
      // width: '10.1%',
      ellipsis: {
        showTitle: false,
      },
      render: property_area => (
        <Tooltip placement="left" title={property_area}>
          {property_area}
        </Tooltip>
      ),
    },
    {
      title: 'Configuration',
      dataIndex: 'property_type',
      // width: '8.7%'
    },
    {
      title: 'Deal Type',
      dataIndex: 'deal_type',
      // width: '6.6%'
    },
    {
      title: 'Budget',
      dataIndex: 'budget',
      // width: '6.6%'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      // width: '9.09%',
      onCell: (record) => {
        return {
          style: {
            backgroundColor:
              record.status === "Interested"
                ? "#ff7fc0"
                : record.status === "Call Not Received"
                  ? "#ff7f8b"
                  : record.status === "Call Back Later"
                    ? "#a2cfff"
                    : record.status === "Site-Visit Scheduled"
                      ? "#b99b9b"
                      : record.status === "Site-Visit Rescheduled"
                        ? "#7cfffe4a"
                        : record.status === "Site-Visit Done"
                          ? "#ffc107"
                          : record.status === "Booking Done"
                            ? "#7cff9a"
                            : record.status === "Plan Postponed"
                              ? "#bd7dff"
                              : record.status === "Not Interested"
                                ? "#cdcadc"
                                : "#ffcece70",
            opacity: 1,
          }
        }
      },
      render: (status, record) => (
        <StatusCell status={status} record={record} ModalFlagCallback={ModalFlagCallback} />
      )
    },
    {
      title: 'Reminder Date',
      dataIndex: 'scheduled_date',
      align: 'center',
      sorter: (a, b) => new Date(a.scheduled_date) - new Date(b.scheduled_date),
      // width: '10.5%',
      render: (scheduled_date) => (
        scheduled_date && moment(scheduled_date).format('DD/MM/YYYY')
      )
    },
    {
      title: () => {
        return (
          <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
            <img src={Whatsapp} alt="whatsapp" style={{ width: "25px", height: "25px", margin: "auto" }} />
          </div>
        )
      },
      dataIndex: 'whatsapp',
      // width: '41px',
      render: (_, record) => (
        <a href={`https://wa.me/91${record.phone}`} target="_blank" rel="noopener noreferrer" style={{ width: "100%", height: "100%", display: "block", textAlign: "center" }}>
          <img src={Whatsapp} alt="whatsapp" style={{ width: "20px", height: "20px", margin: "auto" }} />
        </a>
      )
    },
    {
      title: () => {
        return (
          <MailOutlined style={{ fontSize: "1.25vw", color: "#fff", fontWeight: 'bold', cursor: 'default' }} onClick={(e) => e.preventDefault()} />
        )
      },
      dataIndex: 'manual_email',
      align: 'center',
      // width: '1%',
      render: (_, record) => {
        if (/^\w+([\.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(record.email)) {
          return (
            <a href={`mailto:${record.email}`} target="_blank" rel="noreferrer" style={{ width: "100%", height: "100%", display: "block", textAlign: "center" }}>
              <MailOutlined style={{ fontSize: "1vw", color: "#5D70D2" }} onClick={(e) => e.preventDefault()} />
            </a>
          )
        } else {
          return (<span>NA</span>)
        }
      }
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      // width: '9.09%',
      ellipsis: {
        showTitle: false,
      },
      render: remarks => (
        <Tooltip placement="left" title={remarks}>
          {remarks}
        </Tooltip>
      ),
    }
  ];

  const adminColumns = [
    {
      title: 'Date',
      dataIndex: 'lead_date',
      align: 'center',
      // ellipsis: {
      //   showTitle: false,
      // },
      sorter: (a, b) => new Date(a.lead_date) - new Date(b.lead_date),
      // width: '7.2%',
      render: (lead_date) => (
        // <Tooltip placement="left" title={moment(lead_date).format('DD/MM/YYYY hh:mm a')}>
        moment(lead_date).format('DD/MM/YYYY hh:mm a')
        // </Tooltip>
      )
    },
    {
      title: 'Name',
      dataIndex: 'full_name',
      ellipsis: {
        showTitle: false,
      },
      render: full_name => (
        <Tooltip placement="left" title={full_name}>
          {full_name}
        </Tooltip>
      ),
      // width: '9.09%'
    },
    {
      title: 'Mobile',
      dataIndex: 'phone',
      align: 'center'
      // width: '8%'
    },
    {
      title: 'Source',
      dataIndex: 'source',
      ellipsis: {
        showTitle: false,
      },
      render: source => (
        <Tooltip placement="left" title={source.join(", ")}>
          {source.join(", ")}
        </Tooltip>
      )
      // width: '9.09%'
    },
    {
      title: 'Project',
      dataIndex: 'interested_property',
      ellipsis: {
        showTitle: false,
      },
      render: interested_property => (
        <Tooltip placement="left" title={interested_property}>
          {interested_property}
        </Tooltip>
      ),
      // width: '9.3%'
    },
    {
      title: 'Location',
      dataIndex: 'property_area',
      // width: '10.1%',
      ellipsis: {
        showTitle: false,
      },
      render: property_area => (
        <Tooltip placement="left" title={property_area}>
          {property_area}
        </Tooltip>
      ),
    },
    {
      title: 'Configuration',
      dataIndex: 'property_type',
      // width: '8.7%'
    },
    {
      title: 'Deal Type',
      dataIndex: 'deal_type',
      // width: '6.6%'
    },
    {
      title: 'Budget',
      dataIndex: 'budget',
      // width: '6.6%'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      // width: '9.09%',
      onCell: (record) => {
        return {
          style: {
            background:
              record.status === "Interested"
                ? "#ff7fc0"
                : record.status === "Call Not Received"
                  ? "#ff7f8b"
                  : record.status === "Call Back Later"
                    ? "#a2cfff"
                    : record.status === "Site-Visit Scheduled"
                      ? "#b99b9b"
                      : record.status === "Site-Visit Rescheduled"
                        ? "#7cfffe4a"
                        : record.status === "Site-Visit Done"
                          ? "#ffc107"
                          : record.status === "Booking Done"
                            ? "#7cff9a"
                            : record.status === "Plan Postponed"
                              ? "#bd7dff"
                              : record.status === "Not Interested"
                                ? "#cdcadc"
                                : "#ffcece70"
          }
        }
      },
      render: (status, record) => (
        <StatusCell status={status} record={record} ModalFlagCallback={ModalFlagCallback} />
      )
    },
    {
      title: 'Reminder Date',
      dataIndex: 'scheduled_date',
      align: 'center',
      sorter: (a, b) => new Date(a.scheduled_date) - new Date(b.scheduled_date),
      // width: '10.5%',
      render: (scheduled_date) => (
        scheduled_date && moment(scheduled_date).format('DD/MM/YYYY')
      )
    },
    {
      title: () => {
        return (
          <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
            <img src={Whatsapp} alt="whatsapp" style={{ width: "25px", height: "25px", margin: "auto" }} />
          </div>
        )
      },
      dataIndex: 'whatsapp',
      // width: '1%',
      render: (_, record) => (
        <a href={`https://wa.me/91${record.phone}`} target="_blank" rel="noopener noreferrer" style={{ width: "100%", height: "100%", display: "block", textAlign: "center" }}>
          <img src={Whatsapp} alt="whatsapp" style={{ width: "20px", height: "20px", margin: "auto" }} />
        </a>
      )
    },
    {
      title: () => {
        return (
          <MailOutlined style={{ fontSize: "1.25vw", color: "#fff", fontWeight: 'bold', cursor: 'default' }} onClick={(e) => e.preventDefault()} />
        )
      },
      dataIndex: 'manual_email',
      align: 'center',
      // width: '1%',
      render: (_, record) => {
        if (/^\w+([\.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(record.email)) {
          return (
            <a href={`mailto:${record.email}`} target="_blank" rel="noreferrer" style={{ width: "100%", height: "100%", display: "block", textAlign: "center" }}>
              <MailOutlined style={{ fontSize: "1vw", color: "#5D70D2" }} onClick={(e) => e.preventDefault()} />
            </a>
          )
        } else {
          return (<span>NA</span>)
        }
      }
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      // width: '9.09%',
      ellipsis: {
        showTitle: false,
      },
      render: remarks => (
        <Tooltip placement="left" title={remarks}>
          {remarks}
        </Tooltip>
      ),
    },
    {
      title: 'Assigned To',
      dataIndex: 'SB_name',
      // width: '9.09%',
      ellipsis: {
        showTitle: false,
      },
      render: SB_name => (
        <Tooltip placement="left" title={SB_name}>
          {SB_name}
        </Tooltip>
      ),
    }
  ];

  const dashboardFiltersData = {
    dateStart: "",
    dateEnd: "",
    employees: []
  }

  return (
    <contextData.Provider
      value={{ group, groupCallback, profile, profileCallback, subBrokers, subBrokersCallback, subBrokersActive, leadsTableData, leadsTableDataCallback, tasksData, tasksDataCallback, isStatusModalOpen, userColumns, adminColumns, dashboardFiltersData, tableLoading, toggleTableLoading, resetFilters, resetFiltersCallback }}
    >
      {props.children}
    </contextData.Provider>
  )
}
export default DataContext;