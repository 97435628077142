import React, { useState, useEffect, useContext } from 'react';
import '../css/Tasks.css';
import { Calendar } from 'antd';
import Badge from 'react-bootstrap/Badge';
import { Helmet } from 'react-helmet-async';
import { api } from '../services/Axios';
import Header from './Header';
import dayjs from 'dayjs';
import moment from 'moment';
import { Spin, Table } from 'antd';
import { contextData } from '../context/DataContext';
import { antIcon } from '../services/Constants';
import { themeContext } from '../context/Theme';

const initialDoc = {
  "Incoming": 0,
  "Interested": 0,
  "Call Not Received": 0,
  "Call Back Later": 0,
  "Site-Visit Scheduled": 0,
  "Site-Visit Rescheduled": 0,
  "Site-Visit Done": 0,
  "Booking Done": 0,
  "Plan Postponed": 0,
  "Not Interested": 0
}

const CalendarCellModal = ({ total, index, keyArray, data, color }) => {

  const { group, userColumns, adminColumns, tableLoading } = useContext(contextData);
  const { theme } = useContext(themeContext);
  const [showModal, setShowModal] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ["10", "25", "50", "100"],
      total: data.length,
      // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    },
  });

  const handleTableChange = (pagination, sorter) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  return (
    <>
      <button className={color} onClick={() => { setShowModal(true) }}>
        <span>{keyArray[index]}</span>
        <Badge bg="light" className='text-black'>{total}</Badge>
      </button>
      <div id='tasks-modal-wrapper' style={{ display: showModal ? "block" : "none" }} onClick={() => { setShowModal(false) }}>
        <div className='manual-modal-wrapper'>
          <div className='manual-modal' onClick={(e) => { e.stopPropagation() }}>
            <div className='close-modal-btn'>
              <span onClick={() => { setShowModal(false) }} style={{ color: theme === "light" ? "#000" : "#fff" }}>×</span>
            </div>
            <div className='manual-modal-content'>
              <Table
                bordered
                size="small"
                columns={group === "admin" ? adminColumns : userColumns}
                rowKey={(data) => data.LID}
                dataSource={data}
                pagination={tableParams.pagination}
                loading={{ indicator: <Spin indicator={antIcon} />, spinning: tableLoading }}
                onChange={handleTableChange}
                scroll={{
                  x: "150%",
                  y: 450
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Tasks = () => {

  const newDate = new Date();
  const today = moment(`${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`, 'YYYY-MM-DD');
  const { tasksData, tasksDataCallback } = useContext(contextData);
  const [value, setValue] = useState(() => dayjs(today));
  const [loading, setLoading] = useState(false);

  const onPanelChange = (newValue) => {
    setValue(newValue);
  };

  const getMonthData = (value) => {
    return tasksData.filter((item) => { return (item.scheduled_date !== null && dayjs(item.scheduled_date).month() === value.month() && dayjs(item.scheduled_date).year() === value.year()) || (item.status === "Incoming" && dayjs(item.lead_date).month() === value.month() && dayjs(item.lead_date).year() === value.year()) });
  };

  const monthCellRender = (value) => {
    const validMonthData = getMonthData(value);
    let doc = { ...initialDoc };
    validMonthData.forEach((item) => {
      doc[item.status] += 1;
    })
    const keyArray = Object.keys(doc);
    const valueArray = Object.values(doc);
    return doc !== initialDoc ? (
      <div className="events">
        {valueArray.map((item, i) => (
          item !== 0 &&
          <CalendarCellModal key={i} total={item} index={i} keyArray={keyArray} data={validMonthData.filter((item) => { return item.status === keyArray[i] })} color={dayjs(today).format('YYYY-MM') > dayjs(value).format('YYYY-MM') ? "bg-danger" : "bg-success"} />
        ))}
      </div>
    ) : null;
  };

  const getDateData = (value) => {
    return tasksData.filter((item) => { return (item.scheduled_date !== null && dayjs(item.scheduled_date).date() === value.date() && dayjs(item.scheduled_date).month() === value.month() && dayjs(item.scheduled_date).year() === value.year()) || (item.status === "Incoming" && dayjs(item.lead_date).date() === value.date() && dayjs(item.lead_date).month() === value.month() && dayjs(item.lead_date).year() === value.year()) });
  };

  const dateCellRender = (value) => {
    const validDateData = getDateData(value);
    let doc = { ...initialDoc };
    validDateData.forEach((item) => {
      doc[item.status] += 1;
    })
    const keyArray = Object.keys(doc);
    const valueArray = Object.values(doc);
    return doc !== initialDoc ? (
      <div className="events">
        {valueArray.map((item, i) => (
          item !== 0 &&
          <CalendarCellModal key={i} total={item} index={i} keyArray={keyArray} data={validDateData.filter((item) => { return item.status === keyArray[i] })} color={dayjs(today).format('YYYY-MM-DD') > dayjs(value).format('YYYY-MM-DD') ? "bg-danger" : "bg-success"} />
        ))}
      </div>
    ) : null;
  };

  useEffect(() => {
    setLoading(true);
    api.get(`${process.env.REACT_APP_API_URL}/get_pending_leads`)
      .then((res) => {
        tasksDataCallback(res.data);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [])

  return (
    <div id='tasks'>
      <Helmet>
        <title>Brahmastra - Tasks</title>
      </Helmet>
      <Header title="Tasks" />
      {/* <ConfigProvider
        theme={{
          token: {
            "colorBgContainer": "transparent",
          },
        }}
      > */}
        <Spin indicator={antIcon} spinning={loading} size="large" >
          <Calendar dateCellRender={dateCellRender} monthCellRender={monthCellRender} value={value} onPanelChange={onPanelChange} />
        </Spin>
      {/* </ConfigProvider> */}
    </div>
  );
}

export default Tasks