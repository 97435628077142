import React, { useState } from 'react';
import { s3 } from '../services/AWSConfig';
import { PutObjectCommand } from "@aws-sdk/client-s3";
import { CSVLink } from "react-csv";
import { saveAs } from 'file-saver';
import { api } from '../services/Axios';
import { useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { contextData } from '../context/DataContext';
import { themeContext } from '../context/Theme';
import { apiSourcesArray } from '../services/Constants';
import { DropdownButton } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';

const userHeaders = [
  { label: "Date", key: "lead_date" },
  { label: "Name", key: "full_name" },
  { label: "Mobile", key: "phone" },
  { label: "Source", key: "source" },
  { label: "Project", key: "interested_property" },
  { label: "Location", key: "property_area" },
  { label: "Configuration", key: "property_type" },
  { label: "Deal Type", key: "deal_type" },
  { label: "Budget", key: "budget" },
  { label: "Status", key: "status" },
  { label: "Reminder Date", key: "scheduled_date" },
  { label: "Remarks", key: "remarks" }
];

const adminHeaders = [
  { label: "Date", key: "lead_date" },
  { label: "Name", key: "full_name" },
  { label: "Mobile", key: "phone" },
  { label: "Source", key: "source" },
  { label: "Project", key: "interested_property" },
  { label: "Location", key: "property_area" },
  { label: "Configuration", key: "property_type" },
  { label: "Deal Type", key: "deal_type" },
  { label: "Budget", key: "budget" },
  { label: "Status", key: "status" },
  { label: "Reminder Date", key: "scheduled_date" },
  { label: "Remarks", key: "remarks" },
  { label: "Assigned To", key: "SB_name" }
];

const Header = (props) => {

  const { group, profile, leadsTableData, leadsTableDataCallback, resetFilters, resetFiltersCallback } = useContext(contextData);
  const { theme, switchTheme } = useContext(themeContext);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState(null);

  const showAlert = (message, messageType) => {
    setAlert({
      msg: message,
      type: messageType
    })
    setTimeout(() => {
      setAlert(null);
      setError(false);
      setSuccess(false);
    }, 1500)
  };

  const onDrop = async (acceptedFile) => {
    try {
      props.loadingCallback(true);
      const uploadParams = {
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        Key: `Lead CSVs/${profile.userId}/${acceptedFile[0].name}`,
        Body: acceptedFile[0]
      };
      await s3.send(new PutObjectCommand(uploadParams));
      api.get(`${process.env.REACT_APP_API_URL}/upload_excel?filename=${acceptedFile[0].name}&path=${profile.userId}`)
        .then((response) => {
          if (response.data) {
            leadsTableDataCallback(response.data);
            setSuccess(true);
            showAlert("File Uploaded Successfully!", "success");
          }
          resetFiltersCallback(!resetFilters);
        })
        .catch(err => {
          // console.log("Error", err);
          setError(err.message);
          showAlert("Please select appropriate file!", "danger");
        })
        .finally(() => {
          props.loadingCallback(false);
        });
    } catch (err) {
      // console.log("Error", err);
      setError(true);
      showAlert("Please select appropriate file!", "danger");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const downloadDashboardReports = () => {
    fetch(`${process.env.REACT_APP_API_URL}/download`, {
      method: "POST",
      body: JSON.stringify(props.dashboardReportsData),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(response => response.blob())
      .then(blob => {
        saveAs(blob, "report.xlsx");
      })
      .then(() => {
        api.get(`${process.env.REACT_APP_API_URL}/delete_xlsx`)
      });
  }

  const downloadSample = () => {
    fetch(`${process.env.REACT_APP_API_URL}/sample_excel`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(response => response.blob())
      .then(blob => {
        saveAs(blob, "sample.xlsx");
      })
      .then(() => {
        api.get(`${process.env.REACT_APP_API_URL}/delete_sample_xlsx`)
      });
  }

  const downloadAPIExcel = (source) => {
    fetch(`${process.env.REACT_APP_API_URL}/download_api_sample?source=${source}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(response => response.blob())
      .then(blob => {
        saveAs(blob, `${source} API Integration.xlsx`);
      })
      .catch(err => {
        // console.log("Error", err);
        setError(true);
        showAlert("Please try again later", "danger");
      })
  }

  return (
    <header>
      <div className='main-header-content'>
        <div className="header-title">
          <h1>{props.title}</h1>
        </div>
        <div className="rest-of-header">
          {props.title === "Leads" &&
            <div className='alert-box'>
              {(success || error) && <span className={`alert alert-${alert.type}`} role="alert">
                {alert.msg}
              </span>}
            </div>}
          <div className='buttons-box'>
            {props.title === "Leads" &&
              <>
                <DropdownButton id="api-integration-excel-button" title={<span>API Excel</span>}>
                  {apiSourcesArray.map((elem, i) => {
                    return (
                      <Dropdown.Item key={i} onClick={(e) => downloadAPIExcel(elem)}>{elem}</Dropdown.Item>
                    )
                  })}
                </DropdownButton>
                <button className='sample-csv' onClick={() => downloadSample()}>
                  <span>Sample</span>
                  <svg width="15" height="20" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 30V9L9 0H24V30H0ZM12 22.5L18 16.5L15.9 14.4L13.5 16.725V10.5H10.5V16.725L8.1 14.4L6 16.5L12 22.5Z" fill={theme === "light" ? "#5D70D2" : "#FFFFFF"} />
                  </svg>
                </button>
                <div  {...getRootProps()}>
                  <input type="file" accept=".xlsx" hidden="hidden" style={{ zIndex: -1 }} tabIndex="-1" {...getInputProps()} />
                  <button>
                    <span>Upload</span>
                    <svg width="20" height="25" viewBox="0 0 37 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.65 22.5C16.65 23.0312 16.8276 23.4762 17.1828 23.835C17.5368 24.195 17.9758 24.375 18.5 24.375C19.0242 24.375 19.4639 24.2031 19.8191 23.8594C20.173 23.5156 20.35 23.0781 20.35 22.5469V16.875H23.68C24.1117 16.875 24.3966 16.68 24.5347 16.29C24.6741 15.8987 24.605 15.5625 24.3275 15.2812L19.1475 10.0313C18.9625 9.84375 18.7467 9.75 18.5 9.75C18.2533 9.75 18.0375 9.84375 17.8525 10.0313L12.6725 15.2812C12.395 15.5625 12.3259 15.8987 12.4653 16.29C12.6034 16.68 12.8883 16.875 13.32 16.875H16.65V22.5ZM3.7 30C2.6825 30 1.81177 29.6331 1.0878 28.8994C0.3626 28.1644 0 27.2812 0 26.25V3.75C0 2.71875 0.3626 1.83625 1.0878 1.1025C1.81177 0.3675 2.6825 0 3.7 0H13.2738C13.7671 0 14.2376 0.0937502 14.6853 0.28125C15.1318 0.46875 15.5246 0.734375 15.8637 1.07812L18.5 3.75H33.3C34.3175 3.75 35.1889 4.1175 35.9141 4.8525C36.638 5.58625 37 6.46875 37 7.5V26.25C37 27.2812 36.638 28.1644 35.9141 28.8994C35.1889 29.6331 34.3175 30 33.3 30H3.7Z" fill={theme === "light" ? "#5D70D2" : "#FFFFFF"} />
                    </svg>
                  </button>
                </div>
                <CSVLink
                  data={leadsTableData}
                  headers={group === "admin" ? adminHeaders : userHeaders}
                  filename="leads.csv"
                  target="_blank"
                >
                  <span>Download</span>
                  <svg width="15" height="20" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 30V9L9 0H24V30H0ZM12 22.5L18 16.5L15.9 14.4L13.5 16.725V10.5H10.5V16.725L8.1 14.4L6 16.5L12 22.5Z" fill={theme === "light" ? "#5D70D2" : "#FFFFFF"} />
                  </svg>
                </CSVLink>
              </>}
            {props.title === "Dashboard" &&
              <>
                <button className='reports' onClick={switchTheme}>
                  <span>{theme === "light" ? "Dark Mode" : "Light Mode"}</span>
                  {theme === "dark" ?
                    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20" fill="#FFFFFF">
                      <path d="M480 698.5q51 0 86.75-35.75T602.5 576q0-51-35.75-86.75T480 453.5q-51 0-86.75 35.75T357.5 576q0 51 35.75 86.75T480 698.5Zm-.226 67q-78.774 0-134.024-55.476t-55.25-134.25q0-78.774 55.476-134.024t134.25-55.25q78.774 0 134.024 55.476t55.25 134.25q0 78.774-55.476 134.024t-134.25 55.25ZM84 609.5q-13.8 0-23.65-9.789-9.85-9.79-9.85-23.5 0-13.711 9.85-23.711 9.85-10 23.65-10h96q13.8 0 23.65 9.789 9.85 9.79 9.85 23.5 0 13.711-9.85 23.711-9.85 10-23.65 10H84Zm696 0q-13.8 0-23.65-9.789-9.85-9.79-9.85-23.5 0-13.711 9.85-23.711 9.85-10 23.65-10h96q13.8 0 23.65 9.789 9.85 9.79 9.85 23.5 0 13.711-9.85 23.711-9.85 10-23.65 10h-96Zm-299.789-300q-13.711 0-23.711-9.85-10-9.85-10-23.65v-96q0-13.8 9.789-23.65 9.79-9.85 23.5-9.85 13.711 0 23.711 9.85 10 9.85 10 23.65v96q0 13.8-9.789 23.65-9.79 9.85-23.5 9.85Zm0 696q-13.711 0-23.711-9.85-10-9.85-10-23.65v-96q0-13.8 9.789-23.65 9.79-9.85 23.5-9.85 13.711 0 23.711 9.85 10 9.85 10 23.65v96q0 13.8-9.789 23.65-9.79 9.85-23.5 9.85ZM243.5 387l-50-51q-10.5-9.5-10.5-22.5t11-23.5q9.435-10.5 22.967-10.75Q230.5 279 240 289.5l51 50q11 10.441 11 24.029 0 13.589-11 24.03-9.5 10.441-23.25 10.191T243.5 387ZM720 862.5l-51-50q-10.5-10.167-10.75-23.583Q658 775.5 669 764.5q9.5-10.5 23.25-10.25T716.5 765l50 51q10.5 9.5 10.5 22.5T766.478 862Q756 872.5 743.25 872.75 730.5 873 720 862.5ZM668.441 387Q658 377.5 658.25 363.75T669 339.5l51-50q10.5-10.5 23-10t23 10.543q10.5 9.957 10.75 22.707T766.5 336l-50 51q-10.441 11-24.029 11-13.589 0-24.03-11ZM194 862q-10.5-9.435-10.75-22.967Q183 825.5 193.5 816l50-51q10.167-9.5 23.583-9.75Q280.5 755 291.5 765q10.5 10.5 9.792 23.917-.709 13.416-10.292 23.583l-51 50q-9.5 10.5-22.5 10.25T194 862Zm286-286Z" />
                    </svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20">
                      <path d="M479.931 909.5Q341 909.5 243.75 812.221q-97.25-97.28-97.25-236.25 0-138.971 97.201-236.221t236.06-97.25q11.057 0 22.148.75T524.5 246q-37.5 29.5-59.25 71.5T443.5 408q0 86.042 59.229 145.271Q561.958 612.5 648 612.5q48.065 0 90.282-21.75Q780.5 569 810 531.5q2 11.5 2.75 22.591.75 11.091.75 22.148 0 138.859-97.319 236.06T479.931 909.5Zm.232-67q82.837 0 150.613-48.824Q698.553 744.853 731 666.5q-21.333 5.455-42.333 9.227-21 3.773-40.509 3.773-112.885 0-192.271-79.387Q376.5 520.727 376.5 407.842q0-19.509 3.773-40.509 3.772-21 9.227-42.333-78.353 32.447-127.176 100.145Q213.5 492.842 213.5 576q0 111.036 77.78 188.768T480.163 842.5ZM467 589Z" />
                    </svg>}
                </button>
                <button className='theme' onClick={downloadDashboardReports}>
                  <span>Report</span>
                  <svg width="15" height="20" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 30V9L9 0H24V30H0ZM12 22.5L18 16.5L15.9 14.4L13.5 16.725V10.5H10.5V16.725L8.1 14.4L6 16.5L12 22.5Z" fill={theme === "light" ? "#5D70D2" : "#FFFFFF"} />
                  </svg>
                </button>
              </>}
          </div>
        </div>
      </div>
      <div className='linebreak'>
        <hr></hr>
      </div>
    </header>
  )
}

export default Header