import axios from "axios";
import { api } from "./Axios";

export const redirectToLogin = () => {
    // console.log("redirect to login")
    localStorage.removeItem('token');
    window.location.replace(`https://auth.brahmastra.biz/login?client_id=${process.env.REACT_APP_COGNITO_APP_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_COGNITO_REDIRECT_URI}`);
}

export const getToken = () => {
    if (localStorage.getItem('token')) {
        return localStorage.getItem('token');
    }
    return null;
}

export const checkToken = async () => {
    try {
        const response = await api.get(`${process.env.REACT_APP_API_URL}/check_token`);
        if (response.data.message === 'success') {
            return true;
        }
    } catch (error) {
        // console.log(error);
    }
    return false;
}

export const login = async (code) => {
    if (code) {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/get_token_id`, { "code": code });
            if (response.data.token) {
                localStorage.setItem('token', response.data.token);
                api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
                return true;
            }
            localStorage.removeItem('token');
        } catch (error) {
            // console.log(error);
        }
    }
    return false;
}

export const logout = async () => {
    try {
        const response = await api.get(`${process.env.REACT_APP_API_URL}/logout`);
        if (response.data && response.data.message === "success") {
            localStorage.removeItem('token');
            window.location.replace(`https://auth.brahmastra.biz/logout?response_type=code&client_id=${process.env.REACT_APP_COGNITO_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_COGNITO_REDIRECT_URI}`);
            return true;
        }
    } catch (error) {
        // console.log(error);
    }
    return false;
}