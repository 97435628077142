import React, { useState, useEffect, useContext, useRef } from 'react';
import '../css/ManageEmployees.css';
import Header from './Header';
import { Helmet } from 'react-helmet-async';
import { Table, Tooltip, Spin } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { antIcon } from '../services/Constants';
import { Alert } from 'react-bootstrap';
import { api } from '../services/Axios';
import { contextData } from '../context/DataContext';
import { DropdownButton } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { sourceArray } from '../services/Constants';

const initial = {
  SBID: "",
  name: "",
  sources: [],
  projects: []
}

const StatusModal = ({ record, tableDataCallback, loadingCallback }) => {

  const { subBrokersCallback } = useContext(contextData);
  const [showModal, setShowModal] = useState(false);

  const toggleAccountStatus = (record) => {
    loadingCallback(true);
    api.post(`${process.env.REACT_APP_API_URL}/update_emp`, record)
      .then((response) => {
        // console.log(response);
        tableDataCallback(response.data.employees_table);
        subBrokersCallback(response.data.employees);
      })
      .catch((error) => {
        // console.log(error);
      })
      .finally(() => {
        loadingCallback(false);
      })
  }

  return (
    <>
      <span className={record.active === "Active" ? "account-status" : record.active === "Inactive" ? "account-status text-danger" : "account-status warning"} onClick={() => { return record.active !== "Pending" ? setShowModal(true) : null }}>{record.active}</span>
      <div className='manual-modal-wrapper' style={{ display: showModal ? "block" : "none" }} onClick={() => { setShowModal(false) }}>
        <div className='manual-modal-container'>
          <div className='manual-modal' onClick={(e) => { e.stopPropagation() }}>
            <div className='manual-modal-header'>
              <span>{record.active === "Active" ? "Deactivate Account?" : "Activate Account?"}</span>
              <span className='close-modal-btn' onClick={() => { setShowModal(false) }}>×</span>
            </div>
            <hr />
            <div className='manual-modal-body'>
              <p>Are you sure you want to <span style={{ fontWeight: 500, fontStyle: "italic" }}>{record.active === "Active" ? "deactivate" : "activate"}</span> {record.name}&#39;s account?</p>
            </div>
            <hr />
            <div className='manual-modal-footer'>
              <button className="confirm-btn" onClick={() => { toggleAccountStatus(record); setShowModal(false) }}>Confirm</button>
              <button className="cancel-btn" onClick={() => { setShowModal(false) }}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const DeleteModal = ({ record, showAlert, tableDataCallback, loadingCallback }) => {

  const { subBrokersCallback } = useContext(contextData);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const deleteAccount = (record) => {
    loadingCallback(true);
    api.delete(`${process.env.REACT_APP_API_URL}/delete_emp?SBID=${record.SBID}`)
      .then((response) => {
        tableDataCallback(response.data.employees_table);
        subBrokersCallback(response.data.employees);
        showAlert("Account deleted successfully");
      })
      .catch((error) => {
        // console.log(error);
        showAlert("Error deleting account. Please try again later");
      })
      .finally(() => {
        loadingCallback(false);
      })
  }

  return (
    <>
      <DeleteOutlined onClick={() => setShowDeleteModal(true)} style={{ fontSize: "1.25vw", color: "#dc3545" }} />
      <div className='manual-modal-wrapper' style={{ display: showDeleteModal ? "block" : "none" }} onClick={() => { setShowDeleteModal(false) }}>
        <div className='manual-modal-container'>
          <div className='manual-modal' onClick={(e) => { e.stopPropagation() }}>
            <div className='manual-modal-header'>
              <span>Delete Employee?</span>
              <span className='close-modal-btn' onClick={() => { setShowDeleteModal(false) }}>×</span>
            </div>
            <hr />
            <div className='manual-modal-body'>
              <p>Leads assigned to {record.name} will need to be reassigned. Are you sure you want to delete <span style={{ fontWeight: 500, fontStyle: "italic" }}>{record.name}&#39;s</span> account and all it's data? This action cannot be undone.</p>
            </div>
            <hr />
            <div className='manual-modal-footer'>
              <button className="confirm-btn" onClick={() => { deleteAccount(record); setShowDeleteModal(false) }}>Confirm</button>
              <button className="cancel-btn" onClick={() => { setShowDeleteModal(false) }}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const ManageEmployees = () => {

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
      showSizeChanger: true,
      pageSizeOptions: ["20", "50", "100", "200", "500"],
      // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    },
  });
  const { subBrokers, subBrokersCallback } = useContext(contextData);
  const [tableData, setTableData] = useState([]);
  const [choices, setChoices] = useState(initial);
  const [projects, setProjects] = useState([]);
  const [projectsArray, setProjectsArray] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [loading, setLoading] = useState(false);
  const [dropdownLoading, setDropdownLoading] = useState(false);
  const [error, setError] = useState(null);
  const [alert, setAlert] = useState("");
  const formRef = useRef();

  const handleTableChange = (pagination, sorter) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };

  const tableDataCallback = (data) => {
    setTableData(data);
  }

  const loadingCallback = (value) => {
    setLoading(value);
  }

  const showAlert = (message) => {
    setAlert(message);
    setTimeout(() => {
      setAlert("");
    }, 3000);
  }

  const handleSourceChange = (data) => {
    if (choices.sources.includes(data)) {
      setChoices({
        ...choices,
        sources: choices.sources.filter(source => source !== data)
      })
    } else {
      setChoices({
        ...choices,
        sources: [...choices.sources, data]
      })
    }
  }

  const handleProjectChange = (data) => {
    if (choices.projects.includes(data)) {
      setChoices({
        ...choices,
        projects: choices.projects.filter(project => project !== data)
      })
    } else {
      setChoices({
        ...choices,
        projects: [...choices.projects, data]
      })
    }
  }

  const handleSearch = (e) => {
    setSearchString(e.target.value);
    setProjectsArray(projects.filter(project => project.toLowerCase().includes(e.target.value.toLowerCase())))
  }

  const addNewProject = async () => {
    setDropdownLoading(true);
    try {
      const response = await api.post(`${process.env.REACT_APP_API_URL}/add_property`, { name: searchString });
      if (response.data) {
        setProjects(response.data.properties);
        setProjectsArray(response.data.properties);
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setSearchString("");
      setDropdownLoading(false);
    }
  }

  // const handleClearAll = () => {
  //   setChoices({
  //     ...choices,
  //     sources: []
  //   })
  // }

  const handleSave = (e) => {
    e.preventDefault();
    if (choices.SBID !== "") {
      setLoading(true);
      api.post(`${process.env.REACT_APP_API_URL}/v2/assign_source`, choices)
        .then(response => {
          formRef.current.reset();
          setTableData(response.data.manage_employee);
          subBrokersCallback(response.data.employees);
          setChoices(initial);
          showAlert("Choices updated successfully");
        })
        .catch(error => {
          // console.log(error);
          showAlert("Something went wrong. Please try again later!");
        })
        .finally(() => {
          setLoading(false);
        })
    }
  }

  const handleReset = () => {
    formRef.current.reset();
    setChoices(initial);
    setSearchString("");
    setProjectsArray(projects);
  }

  // console.log(choices.projects)

  const columns = [
    {
      title: 'ID',
      dataIndex: 'SBID',
      align: 'center',
      ellipsis: {
        showTitle: false,
      },
      render: SBID => (
        <Tooltip placement="left" title={SBID}>
          {SBID}
        </Tooltip>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ellipsis: {
        showTitle: false,
      },
      render: full_name => (
        <Tooltip placement="left" title={full_name}>
          {full_name}
        </Tooltip>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email',
      ellipsis: {
        showTitle: false,
      },
      render: email => (
        <Tooltip placement="left" title={email}>
          {email}
        </Tooltip>
      )
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      align: 'center'
    },
    {
      title: 'Total Leads',
      dataIndex: 'total',
      align: 'center',
      sorter: (a, b) => a.total - b.total
    },
    {
      title: 'Converted',
      dataIndex: 'converted',
      align: 'center',
      sorter: (a, b) => a.converted - b.converted
    },
    {
      title: 'Pending',
      dataIndex: 'pending',
      align: 'center',
      sorter: (a, b) => a.pending - b.pending
    },
    {
      title: 'Dropped',
      dataIndex: 'dropped',
      align: 'center',
      sorter: (a, b) => a.dropped - b.dropped
    },
    {
      title: 'Conversion Rate',
      dataIndex: 'converted_percent',
      align: 'center',
      sorter: (a, b) => a.converted_percent - b.converted_percent,
      render: (converted_percent) => (
        <span>{converted_percent}%</span>
      )
    },
    {
      title: 'Leads By Projects',
      dataIndex: 'properties',
      align: 'center',
      ellipsis: {
        showTitle: false,
      },
      render: (properties) => (
        <Tooltip placement="left" title={properties.join(", ")}>
          {properties.join(", ")}
        </Tooltip>
      )
    },
    {
      title: 'Leads By Source',
      dataIndex: 'sources',
      align: 'center',
      ellipsis: {
        showTitle: false,
      },
      render: (sources) => (
        <Tooltip placement="left" title={sources.join(", ")}>
          {sources.join(", ")}
        </Tooltip>
      )
    },
    {
      title: 'Account Status',
      dataIndex: 'active',
      align: 'center',
      sorter: (a, b) => a.active.localeCompare(b.active),
      onCell: (record) => ({
        className: record.active
      }),
      render: (_, record) => {
        return (
          record.SBID === '0' ? 'Active' : <StatusModal record={record} tableDataCallback={tableDataCallback} loadingCallback={loadingCallback} />
        )
      }
    },
    {
      title: 'Delete',
      dataIndex: 'status',
      align: 'center',
      render: (_, record) => {
        return (
          record.SBID === '0' ? '-' : <DeleteModal record={record} showAlert={showAlert} tableDataCallback={tableDataCallback} loadingCallback={loadingCallback} />
        )
      }
    }
  ];

  useEffect(() => {
    const getInitialData = async () => {
      try {
        setLoading(true);
        const response = await api.get(`${process.env.REACT_APP_API_URL}/v2/manage_emp`);
        const res = await api.get(`${process.env.REACT_APP_API_URL}/get_properties`);
        setProjects(res.data.properties);
        setProjectsArray(res.data.properties);
        setTableData(response.data);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: tableData.length,
          },
        });
      } catch (error) {
        setError(error);
        setTableData([]);
        setProjects([]);
        setProjectsArray([]);
      } finally {
        setLoading(false);
      }
    }
    getInitialData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Brahmastra - Manage Employees</title>
      </Helmet>
      <Header title="Manage Employees" />
      <div id="manage-employees">
        {!error ?
          <>
            <form ref={formRef} autoComplete="on" onSubmit={(e) => handleSave(e)}>
              <div>
                <span className='bootstrap-content'>
                  <label htmlFor="employee">Select Employee</label><br />
                  <DropdownButton id="dropdown-basic-button employee" title={<span>{choices.name}</span>}>
                    {subBrokers.map((elem, i) => {
                      // console.log(elem);
                      return (
                        <Dropdown.Item key={i} onClick={() => setChoices({ SBID: elem.SBID, name: elem.name, sources: elem.sources, projects: elem.properties })}>
                          <Tooltip placement="left" title={elem.name}>
                            {elem.name}
                          </Tooltip>
                        </Dropdown.Item>
                      )
                    })}
                  </DropdownButton>
                </span>
                <span className='bootstrap-content'>
                  <label htmlFor="project">Select Default Projects</label><br />
                  <DropdownButton id="dropdown-basic-button project" title={<span>{choices.projects.join(", ")}</span>}>
                    {choices.SBID === "" ?
                      <div className='warning text-center'>Please Select An Employee First</div>
                      :
                      <>
                        <div>
                          <input
                            type="text"
                            id='project-search'
                            placeholder='Enter Project Name'
                            value={searchString}
                            onChange={(e) => handleSearch(e)}
                          />
                          <button disabled={projectsArray.findIndex(item => searchString.toLowerCase() === item.toLowerCase()) !== -1 || searchString === ""} onClick={(e) => { e.preventDefault(); addNewProject() }}>Add</button>
                        </div>
                        <Dropdown.Divider />
                        <Spin indicator={antIcon} spinning={dropdownLoading} size="large" style={{ width: '85%' }}>
                          <div>
                            {projectsArray.length === 0 ?
                              <div className='warning'>No Projects Found</div>
                              :
                              <div>
                                {projectsArray.map((data, i) => {
                                  return (
                                    <div className='dropdown-menu-item' key={i}>
                                      <input
                                        type="checkbox"
                                        id={`${data}-checkbox`}
                                        checked={choices.projects.includes(data)}
                                        onChange={() => handleProjectChange(data)}
                                      />
                                      <label htmlFor={`${data}-checkbox`}>{`${data}`}</label>
                                    </div>
                                  )
                                })}
                              </div>}
                          </div>
                        </Spin>
                        <Dropdown.Divider />
                        <div className='dropdown-outer-item'>
                          <button onClick={() => setChoices({ ...choices, projects: [] })} disabled={!choices.projects.length > 0 ? true : false} >Clear All</button>
                          <Dropdown.Item>
                            <button onClick={() => { return null }}>OK</button>
                          </Dropdown.Item>
                        </div>
                      </>}
                  </DropdownButton>
                </span>
                <span className='bootstrap-content'>
                  <label htmlFor="source">Select Default Sources</label><br />
                  <DropdownButton id="dropdown-basic-button source" title={<span>{choices.sources.join(", ")}</span>}>
                    {choices.SBID === "" ?
                      <div className='warning text-center'>Please Select An Employee First</div>
                      :
                      <>
                        <div>
                          {sourceArray.map((data, i) => {
                            return (
                              // <Sources key={i} identifier={i} data={data} setSourceCallback={setSource} reset={reset} />
                              <div className='dropdown-menu-item' key={i}>
                                <input
                                  type="checkbox"
                                  id={`${data}-checkbox`}
                                  checked={choices.sources.includes(data)}
                                  onChange={() => handleSourceChange(data)}
                                />
                                <label htmlFor={`${data}-checkbox`}>{`${data}`}</label>
                              </div>
                            )
                          })}
                        </div>
                        <Dropdown.Divider />
                        <div className='dropdown-outer-item'>
                          <button onClick={() => setChoices({ ...choices, sources: [] })} disabled={!choices.sources.length > 0 ? true : false} >Clear All</button>
                          <Dropdown.Item>
                            <button onClick={() => { return null }}>OK</button>
                          </Dropdown.Item>
                        </div>
                      </>}
                  </DropdownButton>
                </span>
              </div>
              <div>
                <button type="submit">Save</button>
                <button type="reset" onClick={() => handleReset()}>Reset</button>
              </div>
            </form>
            {alert && <Alert variant={alert.includes("success") ? "success" : "danger"}>{alert}</Alert>}
            <Table
              bordered
              size="small"
              columns={columns}
              rowKey={(tableData) => tableData.SBID}
              dataSource={tableData}
              pagination={tableParams.pagination}
              loading={{ indicator: <Spin indicator={antIcon} />, spinning: loading }}
              onChange={handleTableChange}
              scroll={{
                x: 1500,
                y: 450
              }}
            />
          </>
          :
          <div className='error-text'>{`There is a problem fetching the table data - ${error}`}</div>}
      </div>
    </>
  )
}

export default ManageEmployees