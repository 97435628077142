import React, { useState, useContext } from 'react';
import '../css/LeadProfile.css';
import Header from './Header';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import Whatsapp from '../img/whatsapp.webp';
import { NavLink, useLocation, useNavigate, Outlet } from 'react-router-dom';
import { Tooltip } from 'antd';
import { MailOutlined } from "@ant-design/icons";
import Status from './Status';
import NoMatch from './NoMatch';
import { contextData } from '../context/DataContext';
import { themeContext } from '../context/Theme';

const LeadProfile = () => {

    const { leadsTableData } = useContext(contextData)
    const { theme } = useContext(themeContext);
    const [open, setOpen] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    const showModal = (value) => {
        setOpen(value);
    }

    return (
        <>
            <Helmet>
                <title>Brahmastra - Lead Profile</title>
            </Helmet>
            {leadsTableData.filter((element) => location.state.LID === element.LID).length > 0 ?
                <>
                    <Header title="Lead Profile" />
                    <div id='lead-profile'>
                        <div className="breadcrumb-bar">
                            <h2><NavLink to="/leads">Leads</NavLink> &gt; <span onClick={() => { navigate(`/leads/${leadsTableData.filter((element) => location.state.LID === element.LID)[0].full_name}`, { state: leadsTableData.filter((element) => location.state.LID === element.LID)[0] }); }}>{leadsTableData.filter((element) => location.state.LID === element.LID)[0].full_name}</span></h2>
                            <button onClick={() => { return navigate("/leads"); }}>Back to List</button>
                        </div>
                        <div className="main-content">
                            <div className="left-section">
                                <div className='quick-info-card'>
                                    <h3>{leadsTableData.filter((element) => location.state.LID === element.LID)[0].full_name}</h3>
                                    <span>{leadsTableData.filter((element) => location.state.LID === element.LID)[0].status}</span>
                                    <div className='contact-details'>
                                        <div>
                                            <span className="icon-container">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="#FFFFFF">
                                                    <path d="M3.604 15.812q-.583 0-.989-.406-.407-.406-.407-.989V5.583q0-.583.407-.989.406-.406.989-.406h12.792q.583 0 .989.406.407.406.407.989v8.834q0 .583-.407.989-.406.406-.989.406ZM10 10.917 3.604 7.25v7.167h12.792V7.25Zm0-1.667 6.375-3.667H3.625Zm-6.396-2V5.583v8.834Z" />
                                                </svg>
                                            </span>
                                            <span>{leadsTableData.filter((element) => location.state.LID === element.LID)[0].email}</span>
                                        </div>
                                        <div>
                                            <span className="icon-container">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="#FFFFFF">
                                                    <path d="M4.271 16.792q-.292.02-.688-.209-.395-.229-.395-.771v-2.666q0-.354.208-.625t.562-.354l2.167-.459q.208-.041.396-.01.187.031.375.24l2.083 2q1.604-.917 2.854-2.157 1.25-1.239 2.125-2.823l-1.979-2q-.167-.187-.239-.427-.073-.239-.032-.448l.5-2.145q.104-.355.344-.553.24-.197.594-.197h2.687q.438 0 .729.312.292.312.25.75-.187 2.458-1.25 4.656-1.062 2.198-2.739 3.886-1.677 1.687-3.875 2.739-2.198 1.052-4.677 1.261Z" />
                                                </svg>
                                            </span>
                                            <span>{leadsTableData.filter((element) => location.state.LID === element.LID)[0].phone}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="preferences-card">
                                    <div>
                                        <b style={{ color: "#5D70D2" }}>{leadsTableData.filter((element) => location.state.LID === element.LID)[0].deal_type}</b> Preferences
                                    </div>
                                    <div className='preference-details'>
                                        <div className='card-row'>
                                            <span>Location</span>
                                            <span>{leadsTableData.filter((element) => location.state.LID === element.LID)[0].property_area}</span>
                                        </div>
                                        <div className='card-row'>
                                            <span>Project</span>
                                            <Tooltip title={leadsTableData.filter((element) => location.state.LID === element.LID)[0].interested_property} placement="right">
                                                <span>{leadsTableData.filter((element) => location.state.LID === element.LID)[0].interested_property}</span>
                                            </Tooltip>
                                        </div>
                                        <div className='card-row'>
                                            <span>Configuration</span>
                                            <span>{leadsTableData.filter((element) => location.state.LID === element.LID)[0].property_type}</span>
                                        </div>
                                        <div className='card-row'>
                                            <span>Budget</span>
                                            <span>{leadsTableData.filter((element) => location.state.LID === element.LID)[0].budget}</span>
                                        </div>
                                    </div>
                                </div>
                                {leadsTableData.filter((element) => location.state.LID === element.LID)[0].scheduled_date &&
                                    <div className="reminder-card">
                                        <div>
                                            Next Reminder
                                        </div>
                                        <div className='reminder-details'>
                                            <div className='card-row'>
                                                <span>Date</span>
                                                <span>{moment(leadsTableData.filter((element) => location.state.LID === element.LID)[0].scheduled_date).format('DD/MM/YYYY')}</span>
                                            </div>
                                            <div className='card-row'>
                                                <span>Current Status</span>
                                                <span>{leadsTableData.filter((element) => location.state.LID === element.LID)[0].status}</span>
                                            </div>
                                            <div className='card-row'>
                                                <span>Remarks</span>
                                                <Tooltip title={leadsTableData.filter((element) => location.state.LID === element.LID)[0].remarks} placement="right">
                                                    <span>{leadsTableData.filter((element) => location.state.LID === element.LID)[0].remarks}</span>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                            <div className="right-section">
                                <div className="details-card">
                                    <div className='nav-panel'>
                                        <nav>
                                            <NavLink to="details" style={location.pathname === `/leads/${leadsTableData.filter((element) => location.state.LID === element.LID)[0].full_name}` ? { borderBottom: "2px solid #5D70D2" } : null} state={leadsTableData.filter((element) => location.state.LID === element.LID)[0]}>Details</NavLink>
                                            <NavLink to="activities" state={leadsTableData.filter((element) => location.state.LID === element.LID)[0]}>Activities</NavLink>
                                        </nav>
                                    </div>
                                    <Outlet context={leadsTableData.filter((element) => location.state.LID === element.LID)[0]} />
                                </div>
                                <div className="buttons-menu">
                                    <button onClick={() => { return showModal(true) }}>Update</button>
                                    <Status
                                        record={leadsTableData.filter((element) => location.state.LID === element.LID)[0]}
                                        open={open}
                                        showModal={showModal}
                                    />
                                    <button>
                                        <a href={`https://wa.me/91${location.state.phone}`} target="_blank" rel="noopener noreferrer">
                                            <img src={Whatsapp} alt="whatsapp" style={{ width: "25px", height: "25px", margin: "auto" }} />
                                        </a>
                                    </button>
                                    {(/^\w+([\.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(location.state.email)) && <button>
                                        <a href={`mailto:${location.state.email}`} target="_blank">
                                            <MailOutlined style={{ fontSize: "20px", color: theme === "light" ? "#5D70D2" : "#FFFFFF" }} onClick={(e) => e.preventDefault()} />
                                        </a>
                                    </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <NoMatch />}
        </>
    )
}

export default LeadProfile