import { api } from '../services/Axios';
import React, { useRef, useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import '../css/AddLead.css';
import moment from 'moment';
import Header from './Header';
import { DatePicker, Tooltip } from 'antd';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { Alert } from 'react-bootstrap';
import { Spin } from 'antd';
import { antIcon, reminderStatusArray } from '../services/Constants';
import { contextData } from '../context/DataContext';
import { sourceArray, propertyTypesArray, dealTypesArray, statusArray } from '../services/Constants';

const Sources = ({ identifier, setSourceCallback, data, reset }) => {

  const [isChecked, setIsChecked] = useState(false);

  const handleChange = () => {
    setIsChecked(!isChecked);
    setSourceCallback(data);
  }

  useEffect(() => {
    setIsChecked(false);
  }, [reset])

  return (
    <div className='dropdown-menu-item'>
      <input
        type="checkbox"
        id={`${identifier}-checkbox`}
        checked={isChecked}
        onChange={() => handleChange()}
      />
      <label htmlFor={`${identifier}-checkbox`}>{`${data}`}</label>
    </div>
  )
}

const initial = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  address: "",
  city: "",
  dealType: "",
  interestedAreas: "",
  interestedProperties: "",
  propertyType: "",
  budget: "",
  source: [],
  brokerSource: "",
  brokerSourceName: "",
  status: "Incoming",
  reminderDate: "",
  remarks: "",
  assignTo: "",
  assignToTitle: ""
}

// const brokerInitial = {
//   bfirstName: "",
//   blastName: "",
//   bemail: "",
//   bphone: "",
//   baddress: "",
//   breraID: "",
//   bcompanyName: "",
//   bcompanyEmail: "",
//   bcompanyPhone: "",
//   bcompanyAddress: "",
//   executiveFirstName: "",
//   executiveLastName: "",
//   executivePhone: "",
//   executiveEmail: "",
// }

// const initialBrokerError = {
//   code: "",
//   bfirstName: "",
//   blastName: "",
//   bemail: "",
//   bphone: "",
//   breraID: "",
//   bcompanyEmail: "",
//   bcompanyPhone: "",
//   executiveEmail: "",
//   executivePhone: "",
// }

const initialAlert = {
  code: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  dealType: "",
  propertyType: "",
  budget: "",
  source: "",
  reminderDate: ""
}

const AddLead = () => {

  const { subBrokersActive, group } = useContext(contextData);
  const [externalBrokers, setExternalBrokers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState(initial);
  // const [showModal, setShowModal] = useState(false);
  // const [brokerCredentials, setBrokerCredentials] = useState(brokerInitial);
  const [sourceTitle, setSourceTitle] = useState("Select Sources");
  const [reset, setReset] = useState(false);
  const [variant, setVariant] = useState("");
  const [alert, setAlert] = useState(initialAlert);
  // const [brokerError, setBrokerError] = useState(initialBrokerError);
  const [reminderDatePicker, setReminderDatePicker] = useState(null);
  const [projectsArray, setProjectsArray] = useState([]);
  const [projectSuggestions, setProjectSuggestions] = useState([]);

  const formRef = useRef();
  const projectRef = useRef();
  // const brokerFormRef = useRef();

  // function matchWord(word, input) {
  //   const threshold = Math.ceil(word.length * 0.8); // calculate 80% of the word length
  //   let count = 0;

  //   for (let i = 0; i < word.length; i++) {
  //     if (input.indexOf(word[i]) !== -1) { // check if the character in word exists in the input
  //       count++;
  //     }
  //   }

  //   return count >= threshold;
  // }

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    if (e.target.value.length === 0) {
      setProjectSuggestions(projectsArray)
    } else {
      setProjectSuggestions(projectsArray.filter((item) => item.toLowerCase().includes(e.target.value.toLowerCase())));
    }
  }

  const handleSelect = (value) => {
    projectRef.current.value = value;
    setCredentials((prev) => ({ ...prev, interestedProperties: value }));
    setProjectSuggestions([]);
    projectRef.current.blur();
  }

  const handleOptionChange = (e, value1, value2) => {
    if (credentials.brokerSource !== value1 && credentials.brokerSourceName !== value2) {
      setCredentials((prev) => ({ ...prev, brokerSource: value1 }));
      setCredentials((prev) => ({ ...prev, brokerSourceName: value2 }));
    } else {
      setCredentials((prev) => ({ ...prev, brokerSource: "" }));
      setCredentials((prev) => ({ ...prev, brokerSourceName: "" }));
    }
  };

  const onInputChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  // const onBrokerInputChange = (e) => {
  //   setBrokerCredentials((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  // }

  const setSource = (value) => {
    if (credentials.source.includes(value)) {
      setCredentials((prev) => ({ ...prev, source: prev.source.filter((item) => item !== value) }));
    } else {
      setCredentials((prev) => ({ ...prev, source: [...prev.source, value] }));
    }
  }

  const handleClearAll = () => {
    setCredentials((prev) => ({ ...prev, source: [], brokerSource: "", brokerSourceName: "" }));
    setSourceTitle("Select Sources");
    setReset(!reset);
  }

  const handleReminderDate = (date, dateString) => {
    if (!dateString) {
      setCredentials((prev) => { return { ...prev, reminderDate: "" } });
      setReminderDatePicker(date);
    } else {
      setCredentials((prev) => { return { ...prev, reminderDate: dateString } });
      setReminderDatePicker(date);
    }
  }

  // const addBrokerSource = () => {
  //   if (validateBrokerForm()) {
  //     setLoading(true);
  //     api.post(`${process.env.REACT_APP_API_URL}/add_broker_source`, brokerCredentials)
  //       .then((res) => {
  //         if (res.data.message && res.data.message !== "success") {
  //           setBrokerError((prev) => ({ ...prev, code: res.data.message }));
  //           setTimeout(() => {
  //             setBrokerError((prev) => ({ ...prev, code: "" }));
  //           }, 5000)
  //         } else {
  //           setExternalBrokers(res.data);
  //           setBrokerCredentials(brokerInitial);
  //           setShowModal(false);
  //           brokerFormRef.current.reset();
  //           setVariant("success");
  //           setAlert((prev) => ({ ...prev, code: "Source Broker added successfully!" }));
  //           setTimeout(() => {
  //             setAlert((prev) => ({ ...prev, code: "" }));
  //             setVariant("");
  //           }, 5000)
  //         }
  //       })
  //       .catch((err) => {
  //         // console.log(err);
  //         setBrokerError((prev) => ({ ...prev, code: "Something went wrong, please try again later!" }));
  //         setTimeout(() => {
  //           setBrokerError((prev) => ({ ...prev, code: "" }));
  //         }, 5000)
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       })
  //   }
  // }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      api.post(`${process.env.REACT_APP_API_URL}/send_data`, credentials)
        .then((res) => {
          if (!res.data.properties) {
            setVariant("danger");
            setAlert((prev) => ({ ...prev, code: res.data.message }));
            setTimeout(() => {
              setAlert((prev) => ({ ...prev, code: "" }));
              setVariant("");
            }, 5000)
          } else {
            setProjectsArray(res.data.properties);
            formRef.current.reset();
            setCredentials(initial);
            setReset(!reset);
            setVariant("success");
            setAlert((prev) => ({ ...prev, code: "Lead added successfully!" }));
            setTimeout(() => {
              setAlert((prev) => ({ ...prev, code: "" }));
              setVariant("");
            }, 5000)
          }
        })
        .catch((err) => {
          // console.log(err);
          setVariant("danger");
          setAlert((prev) => ({ ...prev, code: "Something went wrong, please try again later!" }));
          setTimeout(() => {
            setAlert((prev) => ({ ...prev, code: "" }));
            setVariant("");
          }, 5000)
        })
        .finally(() => {
          setLoading(false);
        })
    }
  }

  const handleReset = () => {
    setReset(!reset);
    // setBrokerCredentials(brokerInitial);
    // setBrokerError(initialBrokerError);
    // brokerFormRef.current.reset();
    formRef.current.reset();
    setProjectSuggestions([]);
    handleReminderDate(null, null);
    setCredentials(initial);
    setAlert(initialAlert);
  }

  const validateForm = () => {
    let flag = false;
    if (credentials.firstName === "") {
      setAlert((prev) => ({ ...prev, firstName: "First Name is required" }));
      flag = true;
    }
    if (credentials.lastName === "") {
      setAlert((prev) => ({ ...prev, lastName: "Last Name is required" }));
      flag = true;
    }
    if (/\s/.test(credentials.lastName)) {
      setAlert((prev) => ({ ...prev, lastName: "Last Name cannot contain spaces" }));
      flag = true;
    }
    if (credentials.email && !(/^\w+([\.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(credentials.email))) {
      setAlert((prev) => ({ ...prev, email: "Please enter a valid email address" }));
      flag = true;
    }
    if (credentials.phone.match(/^[0-9]{10}$/) === null) {
      setAlert((prev) => ({ ...prev, phone: "Please enter a valid 10 digit phone number" }));
      flag = true;
    }
    if (credentials.phone === "") {
      setAlert((prev) => ({ ...prev, phone: "Phone is required" }));
      flag = true;
    }
    if (credentials.dealType === "") {
      setAlert((prev) => ({ ...prev, dealType: "Deal Type is required" }));
      flag = true;
    }
    if (credentials.propertyType === "") {
      setAlert((prev) => ({ ...prev, propertyType: "Configuration is required" }));
      flag = true;
    }
    if (credentials.source.length === 0 && credentials.brokerSource === "") {
      setAlert((prev) => ({ ...prev, source: "Source is required" }));
      flag = true;
    }
    if (reminderStatusArray.includes(credentials.status) && credentials.reminderDate === "") {
      setAlert((prev) => ({ ...prev, reminderDate: "Reminder Date is required" }));
      flag = true;
    }
    if (flag) {
      return false;
    } else {
      return true;
    }
  }

  // const validateBrokerForm = () => {
  //   let flag = false;
  //   if (brokerCredentials.bfirstName === "") {
  //     setBrokerError((prev) => ({ ...prev, bfirstName: "First Name is required" }));
  //     flag = true;
  //   }
  //   if (brokerCredentials.blastName === "") {
  //     setBrokerError((prev) => ({ ...prev, blastName: "Last Name is required" }));
  //     flag = true;
  //   }
  //   if (/\s/.test(brokerCredentials.blastName)) {
  //     setBrokerError((prev) => ({ ...prev, blastName: "Last Name cannot contain spaces" }));
  //     flag = true;
  //   }
  //   if (brokerCredentials.bemail && !(/^\w+([\.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(brokerCredentials.bemail))) {
  //     setBrokerError((prev) => ({ ...prev, bemail: "Please enter a valid email address" }));
  //     flag = true;
  //   }
  //   if (brokerCredentials.bphone.match(/^[0-9]{10}$/) === null) {
  //     setBrokerError((prev) => ({ ...prev, bphone: "Please enter a valid 10 digit phone number" }));
  //     flag = true;
  //   }
  //   if (brokerCredentials.bphone === "") {
  //     setBrokerError((prev) => ({ ...prev, bphone: "Phone Number is required" }));
  //     flag = true;
  //   }
  //   if (brokerCredentials.breraID && (brokerCredentials.breraID.match(/^[0-9a-zA-Z]+$/) === null)) {
  //     setBrokerError((prev) => ({ ...prev, breraID: "Please enter a valid (alphanumeric) RERA ID" }));
  //     flag = true;
  //   }
  //   if (brokerCredentials.bcompanyEmail && !(/^\w+([\.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(brokerCredentials.bcompanyEmail))) {
  //     setBrokerError((prev) => ({ ...prev, bcompanyEmail: "Please enter a valid email address" }));
  //     flag = true;
  //   }
  //   if (brokerCredentials.bcompanyPhone && (brokerCredentials.bcompanyPhone.match(/^[0][1-9]\d{9}$/) === null && brokerCredentials.bcompanyPhone.match(/^[1-9]\d{9}$/) === null)) {
  //     setBrokerError((prev) => ({ ...prev, bcompanyPhone: "Please enter a valid phone number" }));
  //     flag = true;
  //   }
  //   if (brokerCredentials.executiveEmail && !(/^\w+([\.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(brokerCredentials.executiveEmail))) {
  //     setBrokerError((prev) => ({ ...prev, executiveEmail: "Please enter a valid email address" }));
  //     flag = true;
  //   }
  //   if (brokerCredentials.executivePhone && brokerCredentials.executivePhone.match(/^[0-9]{10}$/) === null) {
  //     setBrokerError((prev) => ({ ...prev, executivePhone: "Please enter a valid 10 digits phone number" }));
  //     flag = true;
  //   }
  //   if (flag) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  useEffect(() => {
    const handleWindowClick = (event) => {
      if (projectRef.current && !projectRef.current.contains(event.target)) {
        // setIsFocused(false);
        // Execute your code here when the input is blurred
        setProjectSuggestions([]);
      }
    };
    window.addEventListener("click", handleWindowClick);
    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, [projectRef]);

  useEffect(() => {
    if (credentials.firstName !== "") {
      setAlert((prev) => ({ ...prev, firstName: "" }));
    }
    if (credentials.lastName !== "") {
      setAlert((prev) => ({ ...prev, lastName: "" }));
    }
    if (!(/\s/.test(credentials.lastName))) {
      setAlert((prev) => ({ ...prev, lastName: "" }));
    }
    if (credentials.email && /^\w+([\.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(credentials.email)) {
      setAlert((prev) => ({ ...prev, email: "" }));
    }
    if (credentials.email !== "") {
      setAlert((prev) => ({ ...prev, email: "" }));
    }
    if (credentials.phone.match(/^[0-9]{10}$/) !== null) {
      setAlert((prev) => ({ ...prev, phone: "" }));
    }
    if (credentials.phone !== "") {
      setAlert((prev) => ({ ...prev, phone: "" }));
    }
    if (credentials.dealType !== "") {
      setAlert((prev) => ({ ...prev, dealType: "" }));
    }
    if (credentials.propertyType !== "") {
      setAlert((prev) => ({ ...prev, propertyType: "" }));
    }
    if (credentials.source.length !== 0 || credentials.brokerSource !== "") {
      setAlert((prev) => ({ ...prev, source: "" }));
    }
    if (reminderStatusArray.includes(credentials.status) && credentials.reminderDate !== "") {
      setAlert((prev) => ({ ...prev, reminderDate: "" }));
    }
  }, [credentials.firstName, credentials.lastName, credentials.email, credentials.phone, credentials.dealType, credentials.propertyType, credentials.source, credentials.brokerSource, credentials.status, credentials.reminderDate]);

  // useEffect(() => {
  //   if (brokerCredentials.bfirstName !== "") {
  //     setBrokerError((prev) => ({ ...prev, bfirstName: "" }));
  //   }
  //   if (brokerCredentials.blastName !== "") {
  //     setBrokerError((prev) => ({ ...prev, blastName: "" }));
  //   }
  //   if (!(/\s/.test(brokerCredentials.blastName))) {
  //     setBrokerError((prev) => ({ ...prev, blastName: "" }));
  //   }
  //   if (!brokerCredentials.bemail || (brokerCredentials.bemail && (/^\w+([\.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(brokerCredentials.bemail)))) {
  //     setBrokerError((prev) => ({ ...prev, bemail: "" }));
  //   }
  //   if (brokerCredentials.bphone.match(/^[0-9]{10}$/) !== null) {
  //     setBrokerError((prev) => ({ ...prev, bphone: "" }));
  //   }
  //   if (brokerCredentials.bphone !== "") {
  //     setBrokerError((prev) => ({ ...prev, bphone: "" }));
  //   }
  //   if (!brokerCredentials.breraID || (brokerCredentials.breraID && brokerCredentials.breraID.match(/^[0-9a-zA-Z]+$/) !== null)) {
  //     setBrokerError((prev) => ({ ...prev, breraID: "" }));
  //   }
  //   if (!brokerCredentials.bcompanyEmail || (brokerCredentials.bcompanyEmail && (/^\w+([\.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(brokerCredentials.bcompanyEmail)))) {
  //     setBrokerError((prev) => ({ ...prev, bcompanyEmail: "" }));
  //   }
  //   if (!brokerCredentials.bcompanyPhone || (brokerCredentials.bcompanyPhone && (brokerCredentials.bcompanyPhone.match(/^[1-9]\d{9}$/) !== null || brokerCredentials.bcompanyPhone.match(/^[0][1-9]\d{9}$/) !== null))) {
  //     setBrokerError((prev) => ({ ...prev, bcompanyPhone: "" }));
  //   }
  //   if (!brokerCredentials.executiveEmail || (brokerCredentials.executiveEmail && (/^\w+([\.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(brokerCredentials.executiveEmail)))) {
  //     setBrokerError((prev) => ({ ...prev, executiveEmail: "" }));
  //   }
  //   if (!brokerCredentials.executivePhone || (brokerCredentials.executivePhone && brokerCredentials.executivePhone.match(/^[0-9]{10}$/) !== null)) {
  //     setBrokerError((prev) => ({ ...prev, executivePhone: "" }));
  //   }
  // }, [brokerCredentials.bfirstName, brokerCredentials.blastName, brokerCredentials.bemail, brokerCredentials.bphone, brokerCredentials.breraID, brokerCredentials.bcompanyEmail, brokerCredentials.bcompanyPhone, brokerCredentials.executiveEmail, brokerCredentials.executivePhone]);

  useEffect(() => {
    if (credentials.brokerSource !== "" && credentials.source.length > 0) {
      setSourceTitle(credentials.source.join(", ") + ", " + credentials.brokerSourceName);
    } else if (credentials.brokerSource === "" && credentials.source.length > 0) {
      setSourceTitle(credentials.source.join(", "));
    } else if (credentials.brokerSource !== "" && credentials.source.length === 0) {
      setSourceTitle(credentials.brokerSourceName);
    } else {
      setSourceTitle("Select Source");
    }
  }, [credentials.source, credentials.brokerSource]);

  useEffect(() => {
    setLoading(true);
    api.get(`${process.env.REACT_APP_API_URL}/get?table=brokerSource`)
      .then(async (res) => {
        if (res.data) {
          setExternalBrokers(res.data);
          try {
            const response = await api.get(`${process.env.REACT_APP_API_URL}/get_properties`);
            if (response.data) {
              setProjectsArray(response.data.properties);
              // setProjectSuggestions(response.data.properties);
            }
          } catch (error) {
            // console.log(error);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      })
  }, [])

  return (
    <>
      <Helmet>
        <title>Brahmastra - Add Lead</title>
      </Helmet>
      <Header title="Add Lead" />
      <Spin indicator={antIcon} spinning={loading} size="large">
        <form id="add-lead-form" ref={formRef} autoComplete="on" onSubmit={(e) => handleSubmit(e)}>
          {alert.code && <><Alert variant={variant}>{alert.code}</Alert></>}
          <div>
            <span>
              <label htmlFor="firstName">First Name<span style={{ color: "red" }}> *</span></label><br />
              <input type="text" id="firstName" name="firstName" onChange={(e) => onInputChange(e)} /><br />
              {alert.firstName && <><Alert variant="danger">{alert.firstName}</Alert></>}
            </span>
            <span>
              <label htmlFor="lastName">Last Name<span style={{ color: "red" }}> *</span></label><br />
              <input type="text" id="lastName" name="lastName" onChange={(e) => onInputChange(e)} /><br />
              {alert.lastName && <><Alert variant="danger">{alert.lastName}</Alert></>}
            </span>
          </div>
          <div>
            <span>
              <label htmlFor="email">Email</label><br />
              <input type="text" id="email" name="email" onChange={(e) => onInputChange(e)} /><br />
              {alert.email && <><Alert variant="danger">{alert.email}</Alert></>}
            </span>
            <span>
              <label htmlFor="phone">Phone<span style={{ color: "red" }}> *</span></label><br />
              <input type="text" id="phone" name="phone" onChange={(e) => onInputChange(e)} /><br />
              {alert.phone && <><Alert variant="danger">{alert.phone}</Alert></>}
            </span>
          </div>
          <div>
            <span>
              <label htmlFor="address">Address</label><br />
              <input type="text" id="address" name="address" onChange={(e) => onInputChange(e)} /><br />
            </span>
            <span>
              <label htmlFor="city">City</label><br />
              <input type="text" id="city" name="city" onChange={(e) => onInputChange(e)} /><br />
            </span>
          </div>
          <div className='bootstrap-content'>
            <span>
              <label htmlFor="dealType">Deal Type<span style={{ color: "red" }}> *</span></label><br />
              <DropdownButton id="dropdown-basic-button dealTypes" title={credentials.dealType}>
                {dealTypesArray.map((data, i) => {
                  return (
                    <Dropdown.Item key={i} onClick={(e) => { setCredentials((prev) => ({ ...prev, dealType: data })); }}>{data}</Dropdown.Item>
                  )
                })}
              </DropdownButton>
              {alert.dealType && <><Alert variant="danger">{alert.dealType}</Alert></>}
            </span>
            <span>
              <label htmlFor="propertyType">Configuration<span style={{ color: "red" }}> *</span></label><br />
              <DropdownButton id="dropdown-basic-button propertyType" title={credentials.propertyType}>
                {propertyTypesArray.map((data, i) => {
                  return (
                    <Dropdown.Item key={i} onClick={(e) => { setCredentials((prev) => ({ ...prev, propertyType: data })); }}>{data}</Dropdown.Item>
                  )
                })}
              </DropdownButton>
            </span>
          </div>
          <div className='three-cols project-section'>
            <span>
              <label htmlFor="interestedProperties">Project Name</label><br />
              <input type="text" id="interestedProperties" name="interestedProperties" onChange={(e) => handleChange(e)}
                ref={projectRef}
                onFocus={() => {
                  if (credentials.interestedProperties.length === 0) {
                    setProjectSuggestions(projectsArray)
                  } else {
                    setProjectSuggestions(projectsArray.filter((item) => item.toLowerCase().includes(credentials.interestedProperties.toLowerCase())));
                  }
                }}
              // onBlur={() => setProjectSuggestions([])}
              /><br />
              {projectSuggestions.length > 0 && <div className='project-suggestions'>
                {/* {(projectSuggestions.length === 0 && project !== "") && (<div className="add-btn" onClick={() => { setCredentials((prev) => ({ ...prev, interestedProperties: project })); projectRef.current.blur(); }}>
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="#5D70D2">
                    <path d="M4 42V6h19.5v8.25H44v17h-3v-14H23.5v5.25h4v3h-4v5.25h4v3h-4V39h9.75v3Zm3-3h5.25v-5.25H7Zm0-8.25h5.25V25.5H7Zm0-8.25h5.25v-5.25H7Zm0-8.25h5.25V9H7ZM15.25 39h5.25v-5.25h-5.25Zm0-8.25h5.25V25.5h-5.25Zm0-8.25h5.25v-5.25h-5.25Zm0-8.25h5.25V9h-5.25ZM40.65 46v-4.35h-4.4v-3h4.4v-4.4h3v4.4H48v3h-4.35V46Zm-7.9-20.5v-3h3v3Zm0 8.25v-3h3v3Z" />
                  </svg>
                  <span>Add New Project</span>
                </div>)} */}
                {projectSuggestions.map((item, i) => <div className="project" key={i} onClick={() => handleSelect(item)}><Tooltip placement="left" title={item}>
                  {item}
                </Tooltip></div>)}
              </div>}
            </span>
            <span>
              <label htmlFor="interestedAreas">Interested Location</label><br />
              <input type="text" id="interestedAreas" name="interestedAreas" onChange={(e) => onInputChange(e)} /><br />
            </span>
            <span>
              <label htmlFor="budget">Budget</label><br />
              <input type="text" id="budget" name="budget" onChange={(e) => onInputChange(e)} /><br />
            </span>
          </div>
          <div className='bootstrap-content three-cols'>
            <span>
              <label htmlFor="source">Source<span style={{ color: "red" }}> *</span></label><br />
              <DropdownButton id="dropdown-basic-button source" title={<span>{sourceTitle}</span>}>
                <div>
                  <div>
                    {sourceArray.map((data) => {
                      return (
                        <Sources key={data} identifier={data} data={data} setSourceCallback={setSource} reset={reset} />
                      )
                    })}
                  </div>
                  {group !== "individual" &&
                    <>
                      <hr />
                      <p>Broker Sources</p>
                      <hr />
                      <div>
                        {externalBrokers.map((element) => {
                          return (
                            <div className='dropdown-menu-item' key={element.BSID}>
                              <input
                                type="checkbox"
                                id={`${element.BSID}-checkbox`}
                                checked={credentials.brokerSource === element.BSID}
                                onChange={(e) => { handleOptionChange(e, element.BSID, `${element.name} - ${element.companyName}`) }}
                              />
                              <label htmlFor={`${element.BSID}-checkbox`}>{`${element.name} - ${element.companyName}`}</label>
                            </div>
                          )
                        })}
                      </div>
                      {/* <div className="add-btn" onClick={() => { setShowModal(true) }}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="#5D70D2">
                        <path d="M15 10.625V8.833h-1.792v-.958H15V6.083h.958v1.792h1.792v.958h-1.792v1.792ZM8 9.083q-1.021 0-1.698-.677t-.677-1.698q0-1.02.677-1.698.677-.677 1.698-.677t1.698.677q.677.678.677 1.698 0 1.021-.677 1.698T8 9.083Zm-5.542 6.688v-1.313q0-.437.219-.791.219-.355.635-.584 1.084-.625 2.282-.958 1.198-.333 2.406-.333 1.208 0 2.406.333 1.198.333 2.282.958.416.229.635.584.219.354.219.791v1.313Z" />
                      </svg>
                      <span>Add New Broker Source</span>
                    </div> */}
                    </>}
                </div>
                <hr />
                <div className='dropdown-outer-item'>
                  <button onClick={() => handleClearAll()} disabled={credentials.source.length === 0 && credentials.brokerSource === "" ? true : false} >Clear All</button>
                  <Dropdown.Item>
                    <button onClick={() => { return null }}>OK</button>
                  </Dropdown.Item>
                </div>
              </DropdownButton>
              {alert.source && <><Alert variant="danger">{alert.source}</Alert></>}
            </span>
            <span>
              <label htmlFor="status">Status</label><br />
              <DropdownButton id="dropdown-basic-button status" title={credentials.status}>
                {statusArray.map((data, i) => {
                  return (
                    <Dropdown.Item key={i} onClick={(e) => { setCredentials((prev) => ({ ...prev, status: data })); }}>{data}</Dropdown.Item>
                  )
                })}
              </DropdownButton>
            </span>
            <span>
              <label htmlFor="reminderDate">Next Reminder Date{reminderStatusArray.includes(credentials.status) && <span style={{ color: "red" }}> *</span>}</label><br />
              <DatePicker getPopupContainer={() => document.getElementById('date-picker-container')} id="reminderDate" placeholder="Select Date" format='DD/MM/YYYY' onChange={handleReminderDate} value={reminderDatePicker && reminderDatePicker} disabledDate={(current) => { return current && current < moment().startOf('day') }} disabled={!reminderStatusArray.includes(credentials.status)} />
              {alert.reminderDate && <><Alert variant="danger">{alert.reminderDate}</Alert></>}
            </span>
          </div>
          <div className='bootstrap-content'>
            <span style={group === "admin" && subBrokersActive.length > 0 ? { paddingRight: "1vw" } : { width: "100%" }}>
              <label htmlFor="remarks">Remarks</label><br />
              <textarea id="remarks" name="remarks" onChange={(e) => onInputChange(e)} /><br />
            </span>
            {group === "admin" && subBrokersActive.length > 0 &&
              <span style={{ paddingLeft: "1vw" }}>
                <label htmlFor="assignTo">Assign To</label><br />
                {/* <textarea id="assignTo" name="assignTo" onChange={(e) => onInputChange(e)} /><br /> */}
                <DropdownButton id="dropdown-basic-button assignTo" title={credentials.assignToTitle}>
                  {subBrokersActive.map((data) => {
                    return (
                      <Dropdown.Item key={data.SBID} onClick={(e) => { setCredentials((prev) => ({ ...prev, assignTo: data.SBID, assignToTitle: data.name })); }}>{data.name}</Dropdown.Item>
                    )
                  })}
                </DropdownButton>
              </span>}
          </div>
          <div>
            <button type="submit">Add</button>
            <button type="reset" onClick={() => { handleReset() }}>Reset</button>
          </div>
        </form >
      </Spin>
      {/* {group !== "individual" && <div id='add-broker-modal-wrapper' style={{ display: showModal ? "block" : "none" }} onClick={() => { setShowModal(false) }}>
        <div className='manual-modal-wrapper'>
          <div className='manual-modal' onClick={(e) => { e.stopPropagation() }}>
            <div className='manual-modal-header'>
              <span>Add Broker</span>
              <span onClick={() => { setShowModal(false) }}>×</span>
            </div>
            <hr />
            <div className='manual-modal-body'>
              <form id="add-broker-source-form" ref={brokerFormRef}>
                {brokerError.code && <><Alert variant="danger">{brokerError.code}</Alert></>}
                <div>
                  <span>
                    <label htmlFor="bfirstName">First Name<span style={{ color: "red" }}> *</span></label><br />
                    <input type="text" id="bfirstName" name="bfirstName" onChange={(e) => onBrokerInputChange(e)} /><br />
                    {brokerError.bfirstName && <><Alert variant="danger">{brokerError.bfirstName}</Alert></>}
                  </span>
                  <span>
                    <label htmlFor="blastName">Last Name<span style={{ color: "red" }}> *</span></label><br />
                    <input type="text" id="blastName" name="blastName" onChange={(e) => onBrokerInputChange(e)} /><br />
                    {brokerError.blastName && <><Alert variant="danger">{brokerError.blastName}</Alert></>}
                  </span>
                </div>
                <div>
                  <span>
                    <label htmlFor="bemail">Email</label><br />
                    <input type="text" id="bemail" name="bemail" onChange={(e) => onBrokerInputChange(e)} /><br />
                    {brokerError.bemail && <><Alert variant="danger">{brokerError.bemail}</Alert></>}
                  </span>
                  <span>
                    <label htmlFor="bphone">Phone<span style={{ color: "red" }}> *</span></label><br />
                    <input type="text" id="bphone" name="bphone" onChange={(e) => onBrokerInputChange(e)} /><br />
                    {brokerError.bphone && <><Alert variant="danger">{brokerError.bphone}</Alert></>}
                  </span>
                </div>
                <div>
                  <span>
                    <label htmlFor="baddress">Address</label><br />
                    <input type="text" id="baddress" name="baddress" onChange={(e) => onBrokerInputChange(e)} /><br />
                  </span>
                  <span>
                    <label htmlFor="breraID">RERA ID</label><br />
                    <input type="text" id="breraID" name="breraID" onChange={(e) => onBrokerInputChange(e)} /><br />
                    {brokerError.breraID && <><Alert variant="danger">{brokerError.breraID}</Alert></>}
                  </span>
                </div>
                <div>
                  <span>
                    <label htmlFor="bcompanyName">Company Name</label><br />
                    <input type="text" id="bcompanyName" name="bcompanyName" onChange={(e) => onBrokerInputChange(e)} /><br />
                  </span>
                  <span>
                    <label htmlFor='bcompanyEmail'>Company Email</label><br />
                    <input type="text" id="bcompanyEmail" name="bcompanyEmail" onChange={(e) => onBrokerInputChange(e)} /><br />
                    {brokerError.bcompanyEmail && <><Alert variant="danger">{brokerError.bcompanyEmail}</Alert></>}
                  </span>
                </div>
                <div>
                  <span>
                    <label htmlFor="bcompanyPhone">Company Phone</label><br />
                    <input type="text" id="bcompanyPhone" name="bcompanyPhone" onChange={(e) => onBrokerInputChange(e)} /><br />
                    {brokerError.bcompanyPhone && <><Alert variant="danger">{brokerError.bcompanyPhone}</Alert></>}
                  </span>
                  <span>
                    <label htmlFor="bcompanyAddress">Company Address</label><br />
                    <input type="text" id="bcompanyAddress" name="bcompanyAddress" onChange={(e) => onBrokerInputChange(e)} /><br />
                  </span>
                </div>
                <div>
                  <span>
                    <label htmlFor="executiveFirstName">Executive First Name</label><br />
                    <input type="text" id="executiveFirstName" name="executiveFirstName" onChange={(e) => onBrokerInputChange(e)} /><br />
                  </span>
                  <span>
                    <label htmlFor="executiveLastName">Executive Last Name</label><br />
                    <input type="text" id="executiveLastName" name="executiveLastName" onChange={(e) => onBrokerInputChange(e)} /><br />
                  </span>
                </div>
                <div>
                  <span>
                    <label htmlFor="executiveEmail">Executive Email</label><br />
                    <input type="text" id="executiveEmail" name="executiveEmail" onChange={(e) => onBrokerInputChange(e)} /><br />
                    {brokerError.executiveEmail && <><Alert variant="danger">{brokerError.executiveEmail}</Alert></>}
                  </span>
                  <span>
                    <label htmlFor="executivePhone">Executive Phone</label><br />
                    <input type="text" id="executivePhone" name="executivePhone" onChange={(e) => onBrokerInputChange(e)} /><br />
                    {brokerError.executivePhone && <><Alert variant="danger">{brokerError.executivePhone}</Alert></>}
                  </span>
                </div>
              </form>
            </div>
            <hr />
            <div className='manual-modal-footer'>
              <button onClick={addBrokerSource}>Add</button>
              <button onClick={() => { setShowModal(false); setBrokerCredentials(brokerInitial); setBrokerError(initialBrokerError); brokerFormRef.current.reset(); }}>Cancel</button>
            </div>
          </div>
        </div>
      </div>} */}
    </>
  )
}

export default AddLead